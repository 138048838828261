import React, { useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Loader, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { PageContent } from 'Common'
import _ from 'lodash'
import moment from 'moment'
import { USERS_ACTIONS } from 'Mutations/usersMutations'
import { withToastManager } from 'react-toast-notifications'
import { Routes } from 'Constants'
import { withContext } from 'Context'
import { GET_ALL_SUBJECT } from 'Queries/settingQueries'
import { getFilteredSettings } from 'Actions/usersActions'
import CoursesTable from './SubjectTable'

const SubjectList = ({ customFilter, onFilter, learnerDetails, toastManager, filter }) => {
	const { accountSubscriptionId } = useParams()
	const history = useHistory()
	const [selectedSubjects, setSelectedSubjects] = useState([])
	const [dataList, setDataList] = useState([])
	const [courseToLearner] = useMutation(USERS_ACTIONS)
	const [stateloading] = useState(false)
	const [loader, setLoader] = useState(false)

	const { data, error, loading } = useQuery(GET_ALL_SUBJECT, {
		variables: {
			filters: {
				name: customFilter.username,
				sort_param: customFilter.sort_param,
				sort_direction: customFilter.sort_direction,
				tag_id: customFilter.group,
				account_subscription_id: accountSubscriptionId,
			},
		},
		fetchPolicy: 'network-only',
	})

	const handleSubjectsChange = (index, e, { name, checked, value = checked }) => {
		const newSubjects = _.cloneDeep(dataList)
		newSubjects[index][name] = value
		if (name == 'target_completion' && value == '') {
			newSubjects[index][name] = null
		} else {
			newSubjects[index][name] = value
		}
		setDataList(newSubjects)
	}

	const handleSetPage = useCallback(
		page => {
			onFilter({ page })
		},
		[onFilter],
	)

	useEffect(() => {
		if (data) {
			const tempArr = data.AccountSubjects.data.data.map(d => ({ ...d, listType: 'subject' }))
			setDataList(tempArr)
		}
	}, [data])

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)
			const newActiveParam = { name: customFilter.sort_param, order: customFilter.sort_direction }
			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter({ sort_param: newActiveParam.name, sort_direction: newActiveParam.order, page: 1 })
		},
		[onFilter, customFilter],
	)

	const submitCoursesMutation = () => {
		if (learnerDetails.selectedUsers) {
			const isSelectedAll = learnerDetails.selectedUsers === 'all'
			let selectedSubjectUsers = dataList.filter(o1 => selectedSubjects.some(o2 => o1.id === o2.id))
			let tempSelectedSubjects = selectedSubjectUsers.map(item => {
				return {
					id: item.id,
					target_completion:
						item.target_completion && item.target_completion.length <= 2
							? moment()
									.add(item.target_completion, 'days')
									.format('DD-MM-YYYY')
							: item.target_completion,
					refresh_period: item.refresh_period,
				}
			})

			if (tempSelectedSubjects.length > 0) {
				setLoader(true)
				courseToLearner({
					variables: {
						actions: {
							subjects_id: tempSelectedSubjects,
							users_id: isSelectedAll ? null : learnerDetails.selectedUsers,
							all: isSelectedAll,
							account_subscription_id: accountSubscriptionId,
						},
						filters: filter,
					},
				})
					.then(({ data }) => {
						setLoader(false)
						if (data.actions) {
							toastManager.add('Course Added Successfully', { appearance: 'success', autoDismiss: false })
							setSelectedSubjects([])
							history.push(`${Routes.USERS}`)
						}
					})
					.catch(error => {
						console.log('value in error', error)
						setLoader(false)
						setSelectedSubjects([])
					})
					.finally(() => {
						setLoader(false)
					})
			}
		} else {
			toastManager.add('Please choose the learner first.', { appearance: 'error', autoDismiss: false })
		}
	}

	if (loading || loader) return <Loader active />
	if (error) return <span>Something went wrong :(</span>
	if (!dataList.length) return <PageContent.Message message="List is empty" />

	return (
		<>
			<CoursesTable
				dataList={dataList}
				setPage={handleSetPage}
				handleSortTable={handleSortTable}
				selectedSubjects={selectedSubjects}
				setSelectedSubjects={setSelectedSubjects}
				activeSortParam={{
					name: customFilter.sort_param || '',
					order: customFilter.sort_direction || 'asc',
				}}
				handleSubjectsChange={handleSubjectsChange}
			/>
			<div className="course-btn">
				<Button
					primary
					color="blue"
					content="Cancel"
					loading={stateloading}
					disabled={stateloading}
					onClick={() => {
						history.push(`${Routes.USERS}`)
					}}
				/>
				<Button
					primary
					color="blue"
					floated="right"
					content="Add Courses"
					loading={stateloading}
					disabled={selectedSubjects.length > 0 ? false : true}
					onClick={submitCoursesMutation}
				/>
			</div>
		</>
	)
}

SubjectList.propTypes = {
	filter: PropTypes.object.isRequired,
	customFilter: PropTypes.object.isRequired,
	learnerDetails: PropTypes.object.isRequired,
	onFilter: PropTypes.func,
	toastManager: PropTypes.object.isRequired,
}

SubjectList.defaultProps = {
	onFilter: () => {},
}

export default withContext(
	([
		{
			users: { customFilter, learnerDetails, filter },
		},
		dispatch,
	]) => ({
		// state
		customFilter,
		learnerDetails,
		filter,
		// Actions
		onFilter: data => getFilteredSettings(data, dispatch),
	}),
	withToastManager(SubjectList),
)
