import gql from 'graphql-tag'

export const API_ACCOUNT_MUTATION = gql`
	mutation apiAllAccount($webhook_url: String!, $webhook_secret_key: String!) {
		update(webhook_url: $webhook_url, webhook_secret_key: $webhook_secret_key)
	}
`
export const CREATE_TAG_MUTATION = gql`
	mutation tags($name: String!, $description: String) {
		createTag(name: $name, description: $description)
	}
`
export const UPDATE_TAG_MUTATION = gql`
	mutation tags($name: String!, $description: String, $id: Int!) {
		updateTag(name: $name, description: $description, id: $id)
	}
`
export const ADD_COURSES_MUTATION = gql`
	mutation tags($params: TagSubjectsInput) {
		addSubjects(params: $params)
	}
`
export const REMOVE_TAG_COURSES = gql`
	mutation tags($subject_id: Int!, $tag_id: Int!) {
		removeSubject(subject_id: $subject_id, tag_id: $tag_id)
	}
`
export const REMOVE_TAG = gql`
	mutation tags($id: Int!) {
		removeTag(id: $id)
	}
`
export const CREATE_TOPICS_MUTATION = gql`
	mutation topics($name: String!, $description: String, $is_enabled: Boolean, $tag_ids: [Int]) {
		createTopic(name: $name, description: $description, is_enabled: $is_enabled, tag_ids: $tag_ids)
	}
`
export const UPDATE_TOPIC_MUTATION = gql`
	mutation topics($id: Int!, $name: String!, $description: String, $is_enabled: Boolean, $tag_ids: [Int]) {
		updateTopic(id: $id, name: $name, description: $description, is_enabled: $is_enabled, tag_ids: $tag_ids)
	}
`
export const CREATE_SESSION_MUTATION = gql`
	mutation default(
		$topic_id: Int!
		$title: String!
		$description: String
		$venue: String
		$date: String
		$duration: Int
		$places: Int
		$start_time: String
		$end_time: String
		$location_type: Int
		$default_refresher_period: Int
		$location_info: String
		$notes: String
		$session_type: Int
		$status: Int
		$document: Upload
		$document_link: String
		$tag_ids: [Int]
		$uuid: String
		$clone_document_exist: Boolean
		$clone_form: Int
	) {
		createSession(
			topic_id: $topic_id
			title: $title
			description: $description
			venue: $venue
			date: $date
			duration: $duration
			places: $places
			start_time: $start_time
			end_time: $end_time
			location_type: $location_type
			default_refresher_period: $default_refresher_period
			location_info: $location_info
			notes: $notes
			session_type: $session_type
			status: $status
			document: $document
			document_link: $document_link
			tag_ids: $tag_ids
			uuid: $uuid
			clone_document_exist: $clone_document_exist
			clone_form: $clone_form
		)
	}
`
export const UPDATE_SESSION_MUTATION = gql`
	mutation default(
		$id: Int!
		$title: String!
		$description: String
		$venue: String
		$date: String
		$duration: Int
		$places: Int
		$start_time: String
		$end_time: String
		$location_type: Int
		$location_info: String
		$notes: String
		$session_type: Int
		$status: Int
		$document: Upload
		$document_link: String
		$tag_ids: [Int]
		$topic_id: Int
		$default_refresher_period: Int
	) {
		updateSession(
			id: $id
			title: $title
			description: $description
			venue: $venue
			date: $date
			duration: $duration
			places: $places
			start_time: $start_time
			end_time: $end_time
			location_type: $location_type
			location_info: $location_info
			default_refresher_period: $default_refresher_period
			notes: $notes
			session_type: $session_type
			status: $status
			document: $document
			document_link: $document_link
			tag_ids: $tag_ids
			topic_id: $topic_id
		)
	}
`
export const SESSION_STATUS_UPDATE = gql`
	mutation sessions($session_ids: [Int!], $status: Int!) {
		statusChange(session_ids: $session_ids, status: $status)
	}
`
export const ADD_EVIDENCE = gql`
	mutation default(
		$type: String!
		$task_ids: [Int]
		$session_id: Int!
		$user_ids: [Int!]
		$evidence_type: Int!
		$sign_off: Boolean!
		$evidence: String!
		$document: Upload
	) {
		createEvidence(
			type: $type
			task_ids: $task_ids
			session_id: $session_id
			user_ids: $user_ids
			evidence_type: $evidence_type
			sign_off: $sign_off
			evidence: $evidence
			document: $document
		)
	}
`
export const REMOVE_EVIDENCE = gql`
	mutation sessions($evidence_document_id: Int!) {
		deleteEvidenceDocument(evidence_document_id: $evidence_document_id)
	}
`
export const UPDATE_EVIDENCE = gql`
	mutation default($evidence_id: Int!, $evidence: String!, $document: Upload, $evidence_document_id: Int) {
		updateEvidence(
			evidence_id: $evidence_id
			evidence: $evidence
			document: $document
			evidence_document_id: $evidence_document_id
		)
	}
`

// export const ADD_SESSION_TO_LEARNER = gql`
// 	mutation sessions($user_ids: [Int!], $sessions: [SessionInput]) {
// 		bookLearners(user_ids: $user_ids, sessions: $sessions)
// 	}
// `

export const ADD_SESSION_TO_LEARNER = gql`
	mutation sessions($actions: UserActionsInput!, $filters: UserFiltersInput) {
		bookLearners(actions: $actions, filters: $filters)
	}
`

export const ADD_TASK = gql`
	mutation default(
		$name: String!
		$description: String
		$reference: String
		$document_link: String
		$session_id: Int!
		$document: Upload
		$due_date: String
		$user_ids: [Int!]
	) {
		createTask(
			name: $name
			description: $description
			reference: $reference
			document_link: $document_link
			session_id: $session_id
			document: $document
			due_date: $due_date
			user_ids: $user_ids
		)
	}
`
export const COMPLETE_SESSION = gql`
	mutation sessions($pivot_id: Int!, $attendance_status: Int!, $date_completed: String) {
		completeSession(pivot_id: $pivot_id, attendance_status: $attendance_status, date_completed: $date_completed)
	}
`

export const CREATE_CROSS_REFERENCE = gql`
	mutation sessions($user_subject_id: Int!, $evidence_id: Int!, $outcomes: [Int]) {
		createCrossReference(user_subject_id: $user_subject_id, evidence_id: $evidence_id, outcomes: $outcomes)
	}
`
export const SEND_EMAIL = gql`
	mutation sessions(
		$type: String!
		$type_ids: [Int!]
		$session_id: Int
		$email_subject: String!
		$email_body: String!
	) {
		sendMail(
			type: $type
			type_ids: $type_ids
			session_id: $session_id
			email_subject: $email_subject
			email_body: $email_body
		)
	}
`
export const ADD_SESSION_TO_TAG = gql`
	mutation tags($params: TagSessionsInput) {
		addSessions(params: $params)
	}
`
export const REMOVE_TAG_SESSIONS = gql`
	mutation tags($session_id: Int!, $tag_id: Int!) {
		removeSession(session_id: $session_id, tag_id: $tag_id)
	}
`
export const EXPORT_SESSION_ACTIVITY = gql`
	mutation export($filters: ActivityFiltersInput) {
		createActivityExportSession(filters: $filters) {
			id
			__typename
		}
	}
`
export const REMOVE_SESSION_ACTIVITY = gql`
	mutation users($actions: ActivityActionsInput!, $filters: ActivityFiltersInput) {
		activityActionsSessions(actions: $actions, filters: $filters)
	}
`
export const SIGNIN_USING_TOKEN = gql`
	mutation auth($token: String!, $email: String!) {
		tokenSignIn(token: $token, email: $email) {
			id
			first_name
			last_name
			username
			email
			secondary_email
			photo
			created_at
			updated_at
			token
			role
			roleType
			account {
				id
				name
				photo
				manager_notifications
				role
				roleType
				third_party_url
				third_party_button_label
				__typename
			}
			__typename
		}
	}
`
export const DELETE_SESSIONS = gql`
	mutation sessions($session_ids: [Int!]) {
		deleteSession(session_ids: $session_ids)
	}
`
export const DELETE_TOPICS = gql`
	mutation topics($topic_ids: [Int!]) {
		deleteTopic(topic_ids: $topic_ids)
	}
`

export const REMOVE_SESSION_DOC = gql`
	mutation sessions($session_ids: Int!) {
		deleteSessionDocument(session_ids: $session_ids)
	}
`
export const BOOKED_SESSION = gql`
	mutation sessions($current_session_id: Int!, $booked_session_id: Int!) {
		bookedSession(current_session_id: $current_session_id, booked_session_id: $booked_session_id)
	}
`
