import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { GET_SESSION_BY_TOPICID, GET_TOPIC_BYID } from 'Queries/settingQueries'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { Grid, Button, Table, Loader } from 'semantic-ui-react'
import { Routes } from 'Constants'
import { generatePath, useHistory } from 'react-router-dom'
import TopicSessionTable from './TopicSessionTable'
import './style.scss'

const PAGE_NAME = 'Topic Details'

const TopicDetails = ({ setBreadcrumbs, match }) => {
	const history = useHistory()
	const [selectedUsers, setSelectedUsers] = useState([])
	const [topicSessionData, setTopicSessionData] = useState([])
	const {
		params: { id },
	} = match
	const [filter, onFilter] = useState({
		page: 1,
		perPage: 15,
		title: '',
		topic_id: id,
	})

	// query for list data
	const { data: sessionData, loading } = useQuery(GET_SESSION_BY_TOPICID, {
		variables: {
			filters: filter,
		},
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: 'Topics',
				path: '/settings/topics',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	useEffect(() => {
		if (sessionData) setTopicSessionData(sessionData.accountSessions.data.data)
	}, [sessionData])

	//query to get topic details
	const { data: topicDetails, loading: topicLoading } = useQuery(GET_TOPIC_BYID, {
		variables: {
			id: id,
		},
		fetchPolicy: 'network-only',
	})

	if (topicDetails && !topicLoading) {
		var { name, description, tags } = topicDetails.byId
	}

	// set pages in pagination
	const handleSetPage = useCallback(
		page => {
			onFilter(prevState => ({
				...prevState,
				page: page,
			}))
		},
		[onFilter],
	)
	// set sorting
	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)

			const newActiveParam = { name: filter.sort_param, order: filter.sort_direction }

			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter(prevState => ({
				...prevState,
				sort_param: newActiveParam.name,
				sort_direction: newActiveParam.order,
				page: 1,
			}))
		},
		[onFilter, filter],
	)

	const editTopic = () => {
		history.push({
			pathname: generatePath(Routes.EDIT_TOPICS, {
				id: id,
			}),
		})
	}
	return (
		<div className="topic-list">
			<div className="page-title">
				<Grid>
					<Grid.Row>
						<Grid.Column width={12}>
							<h2>{name ? name : '-'}</h2>
						</Grid.Column>
						<Grid.Column width={4}>
							<Button size="big" onClick={editTopic} fluid content="Edit Topic" />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>

			<Table celled className="description-table">
				<Table.Body>
					<Table.Row>
						<Table.Cell colSpan="2">{description ? description : '-'}</Table.Cell>
					</Table.Row>
					<Table.Row className="spacer" />
					<Table.Row>
						<Table.Cell>
							<b>Tags:</b>
							<span>{tags && tags.length > 0 ? _.map(tags, list => list.name).join(', ') : '-'}</span>
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>

			<div style={{ marginTop: '50px' }}>
				<Grid>
					<Grid.Row className="stackable">
						<Grid.Column width={12}>
							<h2>Session</h2>
						</Grid.Column>
						<Grid.Column width={4}>
							<Button
								size="big"
								onClick={() => {
									history.push(`${Routes.SESSION.path}/${id}/create`)
								}}
								fluid
								content="Create Session"
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				{loading ? (
					<Loader active />
				) : topicSessionData.length > 0 ? (
					<TopicSessionTable
						topicSessionData={topicSessionData}
						setPage={handleSetPage}
						page={sessionData.accountSessions.data.current_page}
						perPage={sessionData.accountSessions.data.per_page}
						totalCount={sessionData.accountSessions.data.total}
						length={sessionData.accountSessions.data.total}
						handleSortTable={handleSortTable}
						selectedUsers={selectedUsers}
						setSelectedUsers={setSelectedUsers}
						activeSortParam={{
							name: filter.sort_param || '',
							order: filter.sort_direction || 'asc',
						}}
						match={match}
					/>
				) : (
					<span>No Session Found.</span>
				)}
			</div>
		</div>
	)
}

TopicDetails.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
}

export default withBreadcrumbs(TopicDetails)
