import React from 'react'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { withToastManager } from 'react-toast-notifications'
import { Button } from 'semantic-ui-react'

import { VERIFY_SUBJECT_SECTION } from 'Mutations/subjectsMutations'

import './style.scss'
import PropTypes from 'prop-types'
import moment from 'moment'
import { GET_SECTION_OUTCOMES_MANAGER } from 'Queries/subjectsQueries'

const SignOffSection = ({ match, title, is_verified, toastManager, date_verified, verifiedBy, is_imported }) => {
	const {
		params: { pivotId, sectionId, userId },
	} = match
	const [verifySection] = useMutation(VERIFY_SUBJECT_SECTION, {
		variables: { subject_section_id: parseInt(sectionId), pivot_id: parseInt(pivotId), user_id: parseInt(userId) },
		refetchQueries: [
			{
				query: GET_SECTION_OUTCOMES_MANAGER,
				variables: { id: parseInt(sectionId), pivot_id: parseInt(pivotId), user_id: userId },
			},
		],
	})

	return (
		<div className="verify">
			{title}
			{!is_verified ? (
				<p>
					Please take a moment to review the evidence above and think about the learners competence. If you
					are satisfied they meet the requirements please click the Sign Off button below
				</p>
			) : (
				<p>
					Signed off
					{is_imported && !verifiedBy
						? ' by Learning Archive'
						: verifiedBy
						? ` by ${verifiedBy.first_name} ${verifiedBy.last_name}`
						: ' automatically on ' + moment(date_verified).format('DD/MM/YYYY')}
				</p>
			)}

			{!is_verified && (
				<Button
					size="large"
					onClick={() =>
						verifySection()
							.then(({ data: { verify } }) => {
								if (verify) toastManager.add('Successful', { appearance: 'success', autoDismiss: true })
								else toastManager.add('Failed', { appearance: 'error' })
							})
							.catch(() => {
								toastManager.add('Failed', { appearance: 'error' })
							})
					}
				>
					Sign Off
				</Button>
			)}
		</div>
	)
}

SignOffSection.defaultProps = {
	title: '',
	date_verified: null,
	verifiedBy: null,
}

SignOffSection.propTypes = {
	match: PropTypes.object.isRequired,
	title: PropTypes.string,
	is_verified: PropTypes.bool.isRequired,
	is_imported: PropTypes.bool.isRequired,
	date_verified: PropTypes.string,
	toastManager: PropTypes.object.isRequired,
	verifiedBy: PropTypes.object,
}

export default withRouter(withToastManager(SignOffSection))
