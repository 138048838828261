import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { withToastManager } from 'react-toast-notifications'
import { Grid, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import { Routes } from 'Constants'
import { withContext } from 'Context'
import { CREATE_ASSESSMENT } from 'Mutations/assessmentMutations'
import { setSelectedSection } from 'Actions/subjectActions'

import './style.scss'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { GET_SUBJECT_SECTION_FOR_BREADCRUMBS } from 'Queries/subjectsQueries'
import { PageMetaTags } from 'Common'

const Instructions = ({ history, pageTitle, toastManager, selectedSection, _setSelectedSection, setBreadcrumbs }) => {
	const [startLoading, setStartLoading] = useState(false)
	const [createAssessment] = useMutation(CREATE_ASSESSMENT)

	const { data, loading } = useQuery(GET_SUBJECT_SECTION_FOR_BREADCRUMBS, {
		variables: { id: parseInt(selectedSection.id) },
		fetchPolicy: 'network-only',
	})

	const auto_sign_off_enabled = data ? data.byId.subject.auto_sign_off_enabled : false

	useEffect(() => {
		if (data && data.byId && selectedSection)
			setBreadcrumbs([
				{
					name: 'Courses',
					path: Routes.SUBJECTS.path,
				},
				{
					name: data.byId.subject.name,
					path: Routes.SUBJECTS.path + '/' + selectedSection.pivotId,
				},
				{
					name: selectedSection.name,
					path: Routes.SUBJECTS.path + '/' + selectedSection.pivotId + '/' + selectedSection.id,
				},
				{
					name: 'Assessment Instruction',
				},
			])
	}, [loading])

	const onStart = () => {
		setStartLoading(true)

		createAssessment({
			variables: {
				subject_section_id: selectedSection.id,
				retake: selectedSection.retake,
				pivot_id: selectedSection.pivotId,
			},
		})
			.then(({ errors }) => {
				if (errors && errors.length) {
					toastManager.add(errors[0].debagMessage, { appearance: 'error' })

					return
				}

				_setSelectedSection({
					...selectedSection,
					assessment: true,
				})

				history.push(Routes.ASSESSMENT.questions)
			})
			.finally(() => {
				setStartLoading(false)
			})
	}

	return (
		<div className="assessment-instructions">
			<PageMetaTags title={pageTitle} />
			<div className="page-title">
				<h1>{pageTitle}</h1>
			</div>
			<div className="content">
				<Grid>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<h3>{selectedSection.name}</h3>
						{auto_sign_off_enabled ? (
							<>
								<p>
									This is designed to help you evidence your knowledge and practice. It is not a test.
									Think about your job role and the people you interact with when answering the
									questions.
								</p>
								<p>
									When you have completed the assessment, check your answers and review the learning
									for any that need further action.
								</p>
								<p>
									Then retake the assessment. You won’t have to answer all the questions again, only
									those that require further action.
								</p>
							</>
						) : (
							<>
								<p>
									This is designed to help you evidence your knowledge and practice. It is not a test.
									Your answers should be about your job role and the people you support.
								</p>
								<p>
									To achieve 100% you must discuss the results with your supervisor, then add
									supporting evidence and examples of practice or observations.
								</p>
							</>
						)}
					</Grid.Column>
					<Grid.Column mobile={16} tablet={8} computer={8}>
						<p>
							Take your time and read each question carefully - there is no time limit Your selected
							answer(s) will be highlighted.
						</p>
						<p>
							When you are satisfied with your answer, select <Button content="Next" />
						</p>
						{!loading &&
							(!auto_sign_off_enabled ? (
								<>
									<p>
										If you are not sure of the correct answer select{' '}
										<Button className="reverse" content="Not sure" />
									</p>
									<p>
										If you select <Button className="reverse" content="It depends" /> add an
										explanation or example.
									</p>
								</>
							) : (
								<p>
									Click <Button className="reverse" content="Note" /> to add a comment which you can
									refer back to after the assessment.
								</p>
							))}
						<p>Assessments are saved automatically.</p>

						<div className="actions">
							<Button
								className="secondary"
								loading={startLoading}
								disabled={startLoading}
								content="Start"
								onClick={onStart}
							/>
						</div>
					</Grid.Column>
				</Grid>
			</div>
		</div>
	)
}

Instructions.propTypes = {
	history: PropTypes.object.isRequired,
	pageTitle: PropTypes.string,
	toastManager: PropTypes.object.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
	selectedSection: PropTypes.object.isRequired,
	_setSelectedSection: PropTypes.func.isRequired,
}

Instructions.defaultProps = {
	pageTitle: '',
}

export default withBreadcrumbs(
	withContext(
		([
			{
				subject: { selectedSection },
			},
			dispatch,
		]) => ({
			// State
			selectedSection,
			// Actions
			_setSelectedSection: data => setSelectedSection(data, dispatch),
		}),
		withToastManager(Instructions),
	),
)
