import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Modal } from 'semantic-ui-react'
import { useForm } from 'Hooks/useForm'
import { useMutation } from '@apollo/react-hooks'
import FormComponent from 'Helpers/form'
import { CREATE_TAG_MUTATION, UPDATE_TAG_MUTATION } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'
import './style.scss'

const validate = values => {
	const errors = {}
	const requiredFields = ['tag_name']
	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})
	return errors
}

const AddTags = ({ open, onClose, toastManager, initialFormData }) => {
	const [submitting, setSubmitting] = useState(false)
	const [creatTagMutation] = useMutation(CREATE_TAG_MUTATION)
	const [updateTagMutation] = useMutation(UPDATE_TAG_MUTATION)

	// create tag mutation
	const CreateMutation = variables => {
		creatTagMutation({
			variables,
		})
			.then(({ data }) => {
				if (data.createTag) {
					setSubmitting(false)
					onClose()
					toastManager.add('Tag created.', { appearance: 'success', autoDismiss: true })
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	// update tag mutation
	const UpdateMutation = () => {
		updateTagMutation({
			variables: { name: tag_name, description: description, id: initialFormData.id },
		})
			.then(({ data }) => {
				if (data.updateTag) {
					setSubmitting(false)
					onClose()
					toastManager.add('Tag updated.', { appearance: 'success', autoDismiss: true })
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	const modalCloseHandler = useCallback(
		(skipConfirm = false) => {
			if (handleChange && !skipConfirm) {
				// setConfirmOpen(true)
			} else {
				onClose()
			}
		},
		[handleChange, onClose],
	)

	const {
		values: { tag_name, description },
		errors,
		handleSubmit,
		handleChange,
	} = useForm(
		() => {
			const variables = {
				name: tag_name,
				description: description != undefined ? description : '',
			}
			setSubmitting(true)
			initialFormData && initialFormData.id != undefined ? UpdateMutation() : CreateMutation(variables)
		},
		validate,
		initialFormData,
	)

	return (
		<>
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>{initialFormData && initialFormData.id ? 'Edit Tag' : 'Add Tag'}</Modal.Header>
				<Modal.Content>
					<form autoComplete="off" onSubmit={handleSubmit}>
						<Grid>
							<Grid.Row>
								<Grid.Column tablet={32} computer={16}>
									<Grid.Row className="users-list">
										<FormComponent
											customlabel="Tag name"
											name="tag_name"
											placeholder="Tag Name"
											value={tag_name || ''}
											onChange={handleChange}
											error={errors.tag_name}
										/>
										<FormComponent.Textarea
											rows={3}
											label="Description (Optional)"
											name="description"
											placeholder="Description"
											value={description || ''}
											onChange={handleChange}
											fluid
										/>
									</Grid.Row>
									<Grid className="group-fields">
										<Grid.Column tablet={16} computer={8}>
											<Button
												type="submit"
												loading={submitting}
												disabled={submitting}
												fluid
												content={
													initialFormData && initialFormData.id ? 'Update Tag' : 'Create Tag'
												}
											/>
										</Grid.Column>
										<Grid.Column tablet={16} computer={8}>
											<Button
												className="reverse"
												type="button"
												disabled={submitting}
												content="Cancel"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>
		</>
	)
}

AddTags.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	initialFormData: PropTypes.object.isRequired,
	toastManager: PropTypes.object.isRequired,
}
AddTags.defaultProps = {
	open: false,
}

export default withToastManager(AddTags)
