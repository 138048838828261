import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { withContext } from 'Context'

import { GET_SUBJECT_SECTION_OUTCOME_ACTIVITY } from 'Queries/outcomesQueries'
import { setExportToPdf } from 'Actions/appActions'
import LearningActivityItem from './LearningActivityItem'

const LearningActivityList = ({ match, user, manager, matchForExport, setExportPdf, exportToPdf }) => {
	const {
		params: { pivotId, sectionId, userId },
	} = matchForExport ? matchForExport : match

	const { loading, error, data } = useQuery(GET_SUBJECT_SECTION_OUTCOME_ACTIVITY, {
		variables: {
			pivot_id: String(pivotId),
			subject_section_id: String(sectionId),
			user_id: String(manager ? userId : user.id),
		},
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (matchForExport && !loading) {
			setExportPdf({ ...exportToPdf, activityReady: exportToPdf.activityReady + 1 })
		}
	}, [loading])

	if (loading) return <Loader active inline="centered" style={{ marginTop: '30px' }} />
	if (error || !data.activityBySubjectSection.length) return null

	const { activityBySubjectSection } = data

	return (
		<div className="evidences-list-wrap">
			<h2>Learning viewed</h2>
			<ul className="evidences-list">
				{_.map(activityBySubjectSection, log => (
					<LearningActivityItem key={log.id} {...log} />
				))}
			</ul>
		</div>
	)
}

LearningActivityList.propTypes = {
	match: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	manager: PropTypes.bool.isRequired,
	matchForExport: PropTypes.object,
	exportToPdf: PropTypes.object.isRequired,
	setExportPdf: PropTypes.func.isRequired,
}

LearningActivityList.defaultProps = {
	matchForExport: null,
}

export default withRouter(
	withContext(
		([
			{
				app: { export_to_pdf },
				user: { user },
			},
			dispatch,
		]) => ({
			user,
			exportToPdf: export_to_pdf,
			// actions
			setExportPdf: data => setExportToPdf(data, dispatch),
		}),
		LearningActivityList,
	),
)
