import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import LearnerAddEvidence from 'Modals/session/LearnerAddEvidence'
import EvidenceShow from 'Modals/session/EvidenceShow'
import { Routes } from 'Constants'
import { Session } from 'Common/Session'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import { withContext } from 'Context'
import { allRoles, isAssessorRole, isManagerRole, resolveRole, isSystemAdminRole } from 'Utils/roles'
import SignOff from 'Modals/session/SignOff'
import EvidencesList from 'Common/Session/EvidencesList'
import TaskList from 'Common/Session/TaskList'
import moment from 'moment'
import { Loader } from 'semantic-ui-react'
import { GET_ALL_EVIDENCE, DELEGATES_TASK_LIST, SESSION_DELEGATES, GET_SESSION_BYID } from 'Queries/settingQueries'
import { GET_USER_BY_ID } from '../queries'

const UsersSessions = ({ match, setBreadcrumbs, user, userRole, userRoleType }) => {
	const [openEviModal, setOpenEviModal] = useState(false)
	// const [userName, setUserName] = useState(null)
	const [openSignModal, setOpenSignModal] = useState(false)
	const [currentVideoEvidence, setCurrentVideoEvidence] = useState(null)
	const [openShowEviModal, setOpenShowEviModal] = useState(false)
	const [taskId, setTaskId] = useState(null)
	const [taskEviId, setTaskEviId] = useState(null)
	const {
		params: { id, pivotId, sessionId },
	} = match
	const { loading: sessionLoading, error: sessionError, data: sessionData } = useQuery(GET_USER_BY_ID, {
		variables: { id },
		fetchPolicy: 'cache-and-network',
	})

	useEffect(() => {
		if (sessionData && sessionData.byId) {
			setBreadcrumbs([
				{
					name: 'Learners',
					path: Routes.USERS,
				},
				{
					name: `${sessionData.byId ? sessionData.byId.first_name : '-'} ${
						sessionData.byId ? sessionData.byId.last_name : '-'
					}`,
					path: `${Routes.USERS}/${id}`,
				},
				{
					name: 'Session Details',
				},
			])
			// setUserName(`${sessionData.byId.first_name} ${sessionData.byId.last_name}`)
		}
	}, [sessionLoading, sessionData])

	const manager = isManagerRole(userRole, userRoleType)
	const isAssessor = isAssessorRole(userRole, userRoleType)
	const systemAdmin = isSystemAdminRole(userRole, userRoleType)
	const { loading, error, data, refetch: evidenceRefetch } = useQuery(GET_ALL_EVIDENCE, {
		variables: {
			pivot_id: String(pivotId),
			session_id: String(sessionId),
			user_id: String(manager || isAssessor ? id : user.id),
		},
		fetchPolicy: 'network-only',
	})

	const { loading: taskLoading, error: taskError, data: taskData, refetch: taskRefetch } = useQuery(
		DELEGATES_TASK_LIST,
		{
			variables: {
				session_id: String(sessionId),
				user_id: String(manager || isAssessor ? id : user.id),
			},
			fetchPolicy: 'network-only',
		},
	)

	const createUserModalCloseHandler = () => {
		setOpenEviModal(false)
		setTaskId(null)
		setTaskEviId(null)
	}

	const addEviTask = id => {
		setTaskId(id)
		setTaskEviId(null)
		setOpenEviModal(true)
	}

	const updateTaskEvidence = (eviId, taskId) => {
		setTaskId(taskId)
		setTaskEviId(eviId)
		setOpenEviModal(true)
	}

	const createSignModalCloseHandler = () => {
		setOpenSignModal(false)
	}

	const opentSignModal = () => {
		setOpenSignModal(true)
	}

	const createShowEviCloseHandler = () => {
		setOpenShowEviModal(false)
	}

	const { loading: dLoading, error: dError, data: dData, refetch: dRefetch } = useQuery(SESSION_DELEGATES, {
		variables: {
			session_id: Number(sessionId),
			user_id: Number(manager || isAssessor ? id : user.id),
		},
		fetchPolicy: 'network-only',
	})

	//query to get Session details
	const { data: sessionDetailsNew, loading: sessionLoadingNew } = useQuery(GET_SESSION_BYID, {
		variables: {
			id: Number(sessionId),
			user_id: Number(manager || isAssessor ? id : user.id),
		},
		fetchPolicy: 'no-cache',
	})

	if (loading || sessionLoading || taskLoading || dLoading) return <Loader active />
	if (error || sessionError || taskError || dError) return <span>Something went wrong :(</span>

	const { delegateSessionById } = dData
	const { session } = data

	const isVideo = filename => {
		var ext = filename.split('.').pop()
		switch (ext.toLowerCase()) {
			case 'm4v':
			case 'oog':
			case 'mp4':
			case 'webm':
			case '3gp':
				// etc
				return true
		}
		return false
	}
	const showEviModalOpen = evidenceValue => {
		var ext = evidenceValue.split('.').pop()
		if (isVideo(evidenceValue)) {
			setOpenShowEviModal(true)
			setCurrentVideoEvidence(evidenceValue)
		} else {
			setOpenShowEviModal(false)
			setCurrentVideoEvidence(null)
			let a = document.createElement('a')
			a.href = evidenceValue
			a.target = '_blank'
			a.download = `Evidence. ${ext}`
			a.click()
		}
	}
	return (
		<>
			<Session
				match={match}
				userId={id}
				manager={manager}
				isAssessor={isAssessor}
				learnerSignOffStatus={delegateSessionById.is_completed}
				showEviModalOpen={showEviModalOpen}
				// userName={userName}
				systemAdmin={systemAdmin}
				sessionTopicId={
					delegateSessionById.topics != null &&
					delegateSessionById.topics != undefined &&
					delegateSessionById.topics.length > 0
						? delegateSessionById.topics[0].id
						: null
				}
				learnerTargetCompletionDate={delegateSessionById.target_completion}
				sessionDetails={sessionDetailsNew}
				sessionLoading={sessionLoadingNew}
			/>
			<TaskList
				loading={taskLoading}
				error={taskError}
				data={taskData}
				manager={manager}
				isAssessor={isAssessor}
				sessionId={sessionId}
				addEviTask={addEviTask}
				taskRefetch={taskRefetch}
				updateTaskEvidence={updateTaskEvidence}
				delegateSessionById={delegateSessionById}
				showEviModalOpen={showEviModalOpen}
				match={match}
				selectedUsersId={Number(manager ? id : user.id)}
			/>
			<EvidencesList
				loading={loading}
				error={error}
				data={data}
				manager={manager}
				sessionId={sessionId}
				pivotId={pivotId}
				userId={id}
				evidenceRefetch={evidenceRefetch}
				delegateSessionById={delegateSessionById}
				showEviModalOpen={showEviModalOpen}
				setOpenEviModal={setOpenEviModal}
			/>
			{session.length > 0 ? (
				<>
					{manager && delegateSessionById && !delegateSessionById.is_completed ? (
						// <div className="signoff-box">
						// 	<h2>SignOff</h2>
						// 	<p>
						// 		Please take a moment to review the evidence above and think about the competence. If you
						// 		are satisfied they meet the requirements please click the Sign Off button below
						// 		<p>
						// 			{' '}
						// 			<Button size="large" content="Sign Off" onClick={opentSignModal} />
						// 		</p>{' '}
						// 	</p>
						// </div>
						<></>
					) : delegateSessionById && delegateSessionById.is_completed ? (
						<div className="signoff-box">
							<h2>SignOff</h2>
							<p>
								Signed off by{' '}
								{delegateSessionById.verified_by_name ? (
									<b>{delegateSessionById.verified_by_name}</b>
								) : (
									'-'
								)}
								&nbsp;on{' '}
								{delegateSessionById.user_date_completed
									? moment(delegateSessionById.user_date_completed).format('DD/MM/YYYY')
									: '-'}
								, &nbsp;status{' '}
								{delegateSessionById.attendance_status && delegateSessionById.attendance_status == 1 ? (
									<b>Complete</b>
								) : (
									<b>Not Complete</b>
								)}
								.
							</p>
						</div>
					) : null}
				</>
			) : null}

			{openSignModal && (
				<SignOff
					open={openSignModal}
					onClose={createSignModalCloseHandler}
					pivotId={parseInt(pivotId)}
					dRefetch={dRefetch}
					sessionDate={sessionDetailsNew && sessionDetailsNew.byId ? sessionDetailsNew.byId.date : null}
				/>
			)}
			{openEviModal && (
				<LearnerAddEvidence
					open={openEviModal}
					onClose={createUserModalCloseHandler}
					evidenceRefetch={evidenceRefetch}
					taskRefetch={taskRefetch}
					evidenceId={taskEviId}
					taskId={taskId}
					opentSignModal={opentSignModal}
				/>
			)}

			{openShowEviModal && (
				<EvidenceShow
					open={openShowEviModal}
					onClose={createShowEviCloseHandler}
					videoEvidence={currentVideoEvidence}
				/>
			)}
		</>
	)
}

UsersSessions.propTypes = {
	match: PropTypes.object.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withRouter(
	withContext(
		([
			{
				user: { user },
			},
		]) => ({
			user,
			...resolveRole(user),
		}),
		withBreadcrumbs(UsersSessions),
	),
)
