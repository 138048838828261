import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageMetaTags, CustomPaginator } from 'Common'
import { Grid, Button, Loader, Confirm } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import './style.scss'
import _ from 'lodash'
import classNames from 'classnames'
import FormComponent from 'Helpers/form'
import AddTags from 'Modals/tags/AddTags'
import { withToastManager } from 'react-toast-notifications'
import { Routes } from 'Constants'
import { REMOVE_TAG } from 'Mutations/settingMutations'
import { GET_ALL_TAG_LIST } from 'Queries/settingQueries'
import { generatePath, useHistory } from 'react-router-dom'

const PAGE_NAME = 'Tags'
const Tags = ({ setBreadcrumbs, toastManager }) => {
	const history = useHistory()
	const [openModal, setOpenModal] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [currentTag, setCurrentTag] = useState(0)
	const [tagList, setTag] = useState([])
	const inputRef = useRef(null)
	const [filters, setFilters] = useState({
		name: '',
		page: 1,
		perPage: 10,
	})
	const [totalCount, setTotalCount] = useState(10)
	const debounceSetName = useMemo(
		() =>
			_.debounce(
				value =>
					setFilters(prevState => ({
						...prevState,
						name: value,
						page: 1,
					})),
				500,
			),
		[setFilters],
	)
	const { data, loading, refetch } = useQuery(GET_ALL_TAG_LIST, {
		variables: { filters },
		fetchPolicy: 'network-only',
	})
	const [removeTag] = useMutation(REMOVE_TAG)
	useEffect(() => {
		if (data) {
			setTag(data.accountTags.data.data)
			setTotalCount(data.accountTags.data.total)
		}
	}, [data])

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	const createUserModalCloseHandler = () => {
		setOpenModal(false)
		refetch()
	}
	const viewTag = id => {
		history.push({
			pathname: generatePath(Routes.TAGS_VIEW, {
				id: id,
			}),
		})
	}

	const onClearSearch = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.inputRef.value = ''
			debounceSetName('')
		}
	}, [inputRef, debounceSetName])

	const handleChangeSearch = useCallback(
		e => {
			debounceSetName(e.target.value)
		},
		[debounceSetName],
	)
	const setPage = pageValue => {
		setFilters(prevState => ({
			...prevState,
			page: pageValue,
		}))
	}

	const onConfirm = useCallback(() => {
		removeTag({
			variables: { id: currentTag },
		})
			.then(({ data }) => {
				if (data.removeTag) {
					toastManager.add('Deleted successfully.', { appearance: 'success', autoDismiss: true })
					refetch()
				}
			})
			.catch(error => {
				console.log('error', error)
			})
			.finally(error => {
				console.log('error', error)
			})
		setOpenDeleteModal(false)
	}, [openDeleteModal])

	const onCancel = useCallback(() => {
		setOpenDeleteModal(false)
	}, [openDeleteModal])

	return (
		<div className="tag-list">
			<PageMetaTags title={PAGE_NAME} />
			<h2>{PAGE_NAME}</h2>
			<Grid columns={3}>
				<Grid.Row>
					<Grid.Column></Grid.Column>
					<Grid.Column></Grid.Column>
					<Grid.Column>
						<Button size="large" fluid content="Create Tag" onClick={() => setOpenModal(true)} />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column />
					<Grid.Column />
					<Grid.Column>
						<FormComponent
							inputClassName="search"
							label={
								<button
									className={classNames({ active: filters.name.length })}
									type="button"
									onClick={onClearSearch}
								>
									{!!filters.name.length && <i className="fal fa-times-circle" />}
								</button>
							}
							name="username"
							defaultValue={filters.name}
							onChange={handleChangeSearch}
							placeholder="Search"
							labelPosition="right"
							inputRef={inputRef}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			{loading ? (
				<Loader active />
			) : tagList.length > 0 ? (
				<>
					<Grid columns={5}>
						<Grid.Row>
							{_.map(tagList, list => (
								<Grid.Column className="custom-column-div">
									<div
										onKeyPress={() => {
											viewTag(list.id)
										}}
										onClick={() => viewTag(list.id)}
										role="button"
										tabIndex="0"
										className="custom-column"
									>
										<div className="tag-listing">{list.name}</div>
									</div>
									<button
										className="delete-btn"
										type="button"
										onClick={() => {
											setOpenDeleteModal(true)
											setCurrentTag(list.id)
										}}
									>
										x
									</button>
								</Grid.Column>
							))}
						</Grid.Row>
					</Grid>
					<CustomPaginator
						totalCount={totalCount}
						perPage={filters.perPage}
						pageChangeHandler={setPage}
						length={totalCount}
						activePage={filters.page}
					/>
					<Confirm
						open={openDeleteModal}
						size="mini"
						content="You are about to delete the Tag?"
						cancelButton={<Button className="reverse" disabled={false} content="Cancel" />}
						confirmButton={<Button loading={false} disabled={false} content="Confirm" />}
						onCancel={onCancel}
						onConfirm={onConfirm}
					/>
				</>
			) : (
				<span>No Tags Found.</span>
			)}
			{openModal && <AddTags open={openModal} onClose={createUserModalCloseHandler} />}
		</div>
	)
}

Tags.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
}

export default withBreadcrumbs(withToastManager(Tags))
