import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon, Button, Grid, Modal, Loader } from 'semantic-ui-react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { withToastManager } from 'react-toast-notifications'
import { withContext } from 'Context/index'
import { withRouter } from 'react-router-dom'
import { isManagerRole, resolveRole } from 'Utils/roles'
import './style.scss'
import { GET_USER_SUBJECTS_BY_ID } from 'Pages/Users/SingleUser/UserSubjects/queries'
import { GET_SUBJECT_BY_ID } from 'Pages/Subjects/SingleSubject/queries'
import { UserSubjectSelect } from 'Common/UserSubjectSelect'
import { GET_SECTION_OUTCOMES_MANAGER } from 'Queries/subjectsQueries'
import { CREATE_CROSS_REFERENCE } from 'Mutations/settingMutations'
import OutcomesList from './Outcomes/OutcomesList'

const CrossReference = ({ open, onClose, authUser, evidenceId, userId }) => {
	const [subjectSection, setSubjectSection] = useState(null)
	const [outcomeStatus, setOutcomeStatus] = useState(null)
	const isManager = isManagerRole(authUser.account.role, authUser.account.roleType)
	const [selectedItems, setSelectedItems] = useState([])
	const [outcomes, setOutcomes] = useState(false)
	const [subjectData, setSubjectData] = useState(false)
	const [outcomesType, setOutcomesType] = useState(false)
	const [outcomesLading, setOutcomesLading] = useState(false)
	const [crossReferenceMutation] = useMutation(CREATE_CROSS_REFERENCE)
	const [getUserSubjectsQuery, { data: userSubjects, loading: userSubjectsLoading }] = useLazyQuery(
		GET_USER_SUBJECTS_BY_ID,
		{
			fetchPolicy: 'network-only',
		},
	)
	const [getUserSubjecsQuery, { data: userSubject }] = useLazyQuery(GET_SUBJECT_BY_ID, {
		fetchPolicy: 'network-only',
	})
	const [getSectionOutcomes, { data: outcomesData }] = useLazyQuery(GET_SECTION_OUTCOMES_MANAGER, {
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (evidenceId) {
			getUserSubjectsQuery({ variables: { id: userId } })
		}
	}, [evidenceId])
	useEffect(() => {
		if (outcomesData) {
			setOutcomes(false)
			let joinedOutcomes = outcomesData.byId.outcomes.filter(outcome => {
				return outcome.observable
			})
			if (outcomes) {
				joinedOutcomes = outcomes.concat(
					outcomesData.byId.outcomes.filter(outcome => {
						return outcome.observable
					}),
				)
			}
			setOutcomes(joinedOutcomes)
			let totalOutcomes = joinedOutcomes.length
			let correctOutcomes = joinedOutcomes.filter(outcome => {
				return outcome.observationStatus == 'observation_done'
			}).length
			setOutcomesType(outcomesData.byId.type)
			setOutcomeStatus(correctOutcomes + ' / ' + totalOutcomes)
		}
	}, [outcomesData])

	const handleSelectSubjectChange = useCallback(
		(e, { value }) => {
			setSubjectSection(value)
			getUserSubjecsQuery({ variables: { pivot_id: parseInt(value), user_id: parseInt(userId) } })
		},
		[userId],
	)

	useEffect(() => {
		if (userSubject) {
			setSubjectData(userSubject.byId)
			let sections = userSubject.byId.sections
			let index = 1
			setOutcomesLading(true)
			for (let section of sections) {
				setTimeout(() => {
					getSectionOutcomes({
						variables: {
							pivot_id: parseInt(subjectSection),
							id: parseInt(section.id),
							user_id: isManager ? userId : authUser.id,
						},
					})
					if (sections.length + 1 == index) {
						setOutcomesLading(false)
					}
				}, 1500 * index)
				index++
			}
		}
	}, [userSubject])

	const modalCloseHandler = useCallback(() => {
		onClose()
	}, [onClose])

	const crossReferenceHandler = useCallback(() => {
		const variables = {
			user_subject_id: parseInt(subjectSection),
			evidence_id: parseInt(evidenceId),
			outcomes: selectedItems,
		}
		crossReferenceMutation({ variables: variables })
		onClose()
	}, [onClose, selectedItems, subjectSection, evidenceId])

	if (userSubjectsLoading) return <Loader active />
	if (outcomesLading) return <Loader active />
	return (
		<>
			<Modal
				className="form-modal add-users-modal cross-reference-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Cross Reference of Evidence</Modal.Header>
				<Modal.Content>
					<form autoComplete="off">
						<Grid>
							{!subjectData && (
								<Grid.Row>
									<Grid.Column>
										Please select the Click course you would like to cross reference this evidence
										against. The counter on the left show the total number of outcomes which require
										an observation and how many have already been marked as correct.
									</Grid.Column>
								</Grid.Row>
							)}
							{!subjectData && (
								<Grid.Row>
									<Grid.Column>
										{userSubjects && (
											<UserSubjectSelect
												subjects={userSubjects.byId.subjectsByCurrentAccount}
												onChange={handleSelectSubjectChange}
											/>
										)}
									</Grid.Column>
								</Grid.Row>
							)}
						</Grid>
						{subjectData && (
							<Grid>
								<Grid.Row>
									<Grid.Column className="subject-name-div">
										{subjectData.name} <div className="outcome-status">{outcomeStatus}</div>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										{outcomes && (
											<OutcomesList
												outcomes={outcomes}
												subjectSectionType={outcomesType}
												selectedItems={selectedItems}
												setSelectedItems={setSelectedItems}
												manager={isManager}
												isExport={false}
											/>
										)}
										{!outcomes && <Loader active />}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						)}

						<Grid>
							{!userSubject && (
								<Grid.Row>
									<Grid.Column>
										<Grid className="group-fields">
											<Grid.Column>
												<Button
													className="reverse"
													size="medium"
													type="button"
													content="Cancel"
													fluid
													onClick={modalCloseHandler}
												/>
											</Grid.Column>
										</Grid>
									</Grid.Column>
								</Grid.Row>
							)}
							{outcomes && (
								<Grid.Row>
									<Grid.Column>
										<Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
											<Grid item xs={4} sm={4} md={4}>
												<Grid.Row>
													<Grid className="group-fields">
														<Grid.Column>
															<Button size="small" fluid onClick={crossReferenceHandler}>
																<Icon name="linkify" color="grey" size="big" />
																Cross Reference
															</Button>
															<br></br>
															<Button
																className="reverse"
																size="medium"
																type="button"
																content="Cancel"
																fluid
																onClick={modalCloseHandler}
															/>
														</Grid.Column>
													</Grid>
												</Grid.Row>
											</Grid>
											<Grid item xs={6} sm={6} md={6} className="warning">
												<b>Warning:</b> You cannot undo this action. Please make sure you have
												selected the correct outcomes. Cross referencing these outcomes will
												link this evidence to the Click course and mark the observation/s you
												have selected as complete on the Click course.
											</Grid>
										</Grid>
									</Grid.Column>
								</Grid.Row>
							)}
						</Grid>
					</form>
				</Modal.Content>
			</Modal>
		</>
	)
}

CrossReference.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	authUser: PropTypes.object.isRequired,
	evidenceId: PropTypes.number,
	userId: PropTypes.any,
}

CrossReference.defaultProps = {
	open: false,
	evidenceId: null,
	userId: null,
}

export default withToastManager(
	withRouter(
		withContext(
			([
				{
					user: { user },
				},
			]) => ({
				...resolveRole(user),
				authUser: user,
			}),
			CrossReference,
		),
	),
)
