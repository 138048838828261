import { useState } from 'react'
import _ from 'lodash'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { SWITCH_DASHBOARD } from 'Mutations/dashboardMutations'
import { GET_USER_ROLES } from 'Queries/dashboardQueries'
import { userGroupRoles, userRoles } from 'Constants'
import { isManagerRole } from 'Utils/roles'

export default function useRoles({ user, changeRole }) {
	const [switchDashboardLoading, setSwitchDashboardLoading] = useState(false)

	const [switchDashboard] = useMutation(SWITCH_DASHBOARD)
	const userRolesQuery = useQuery(GET_USER_ROLES, {
		skip: !user,
		variables: { id: user && user.id },
		fetchPolicy: 'network-only',
	})

	const { data } = userRolesQuery

	const getGroups = () => {
		if (!data) {
			return []
		}

		return data.byId.groups
	}

	const getUserRoles = () => {
		if (!data) {
			return []
		}

		return data.byId.currentAccount.roles
	}

	const isManager = () => {
		return isManagerRole(user.account.role, user.account.roleType)
	}

	const isGroupUser = () => {
		return !_.isEmpty(getGroups())
	}

	/**Taken from SwitchDashboard.js, authored by Pratim */
	const handleSwitchDashboard = () => {
		setSwitchDashboardLoading(true)

		switchDashboard()
			.then(({ data }) => {
				if (data)
					changeRole({
						...user,
						account: {
							...user.account,
							role: _.find(isGroupUser() ? userGroupRoles : userRoles, [
								'id',
								isManager() ? 2 : isGroupUser() ? getGroups()[0].role : getUserRoles()[0].role,
							]).id,
						},
					})
			})
			.finally(() => setSwitchDashboardLoading(false))
	}

	return {
		...userRolesQuery,
		switchDashboardLoading,
		switchDashboard: handleSwitchDashboard,
		isManager,
		isGroupUser,
		getGroups,
		getUserRoles,
	}
}
