import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Grid, Tab } from 'semantic-ui-react'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { withContext } from 'Context'
import classNames from 'classnames'
import FormComponent from 'Helpers/form'
import { GET_ALL_SUBJECT, GET_TAG_BYID, ACCOUNT_SESSION_LIST } from 'Queries/settingQueries'
import AddCourseList from './AddCourseList'
import SessionList from './SessionList'
import './style.scss'

const AddCourses = ({ match, setBreadcrumbs, customFilter }) => {
	const [sessionVisible, setSessionVisible] = useState(false)
	const inputRef = useRef(null)
	const [filterCustom, onFilterCustom] = useState({
		page: 1,
		perPage: 15,
		name: '',
	})
	const {
		params: { id },
	} = match

	// courses data query
	const { data, loading } = useQuery(GET_ALL_SUBJECT, {
		variables: {
			filters: filterCustom,
		},
		fetchPolicy: 'network-only',
	})
	// breadcrumb data query
	const { data: breadcrumbData } = useQuery(GET_TAG_BYID, {
		variables: {
			id: id,
		},
		fetchPolicy: 'network-only',
	})
	// session data query
	const { data: sessionData, loading: sessionLoading } = useQuery(ACCOUNT_SESSION_LIST, {
		variables: {
			filters: {
				title: filterCustom.name,
				sort_param: customFilter.sort_param,
				sort_direction: customFilter.sort_direction,
			},
		},
		fetchPolicy: 'network-only',
	})

	if (breadcrumbData) {
		var { name } = breadcrumbData.byId
	}

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: 'Tags',
				path: '/settings/tags',
			},
			{
				name: name ? name : '-',
				path: '/settings/tags/' + id,
			},
			{
				name: 'Add Learning',
			},
		])
	}, [breadcrumbData])

	useEffect(() => {
		if (sessionData) {
			if (sessionData.accountSessions.data.data.length > 0) setSessionVisible(true)
		}
	}, [sessionData])

	const debounceSetName = useMemo(
		() =>
			_.debounce(
				value =>
					onFilterCustom(prevState => ({
						...prevState,
						name: value,
						page: 1,
					})),
				500,
			),
		[onFilterCustom],
	)

	const onClearSearch = useCallback(() => {
		if (inputRef.current) {
			inputRef.current.inputRef.value = ''
			debounceSetName('')
		}
	}, [inputRef, debounceSetName])

	const handleChangeSearch = useCallback(
		e => {
			debounceSetName(e.target.value)
		},
		[debounceSetName],
	)

	const panes = [
		{
			menuItem: 'Courses',
			render: () => (
				<Tab.Pane>
					{data && (
						<AddCourseList
							data={data}
							activeSortParam={{
								name: filterCustom.sort_param || '',
								order: filterCustom.sort_direction || 'asc',
							}}
							filter={filterCustom}
							onFilter={onFilterCustom}
							match={match}
							courseLoading={loading}
						/>
					)}
				</Tab.Pane>
			),
		},
		sessionVisible
			? {
					menuItem: 'Sessions',
					render: () => (
						<Tab.Pane>
							<SessionList sessionData={sessionData} sessionLoading={sessionLoading} match={match} />
						</Tab.Pane>
					),
			  } // eslint-disable-line no-mixed-spaces-and-tabs
			: null,
	]
	return (
		<div className="tag-list">
			<div className="page-title">
				<Grid>
					<Grid.Row>
						<Grid.Column mobile={16} tablet={8} computer={8} largeScreen={12} widescreen={12}>
							<h2>Add Learning to {name ? name : '-'}</h2>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={8} computer={8} largeScreen={4} widescreen={4}>
							<FormComponent
								inputClassName="search"
								label={
									<button
										className={classNames({ active: filterCustom.name.length })}
										type="button"
										onClick={onClearSearch}
									>
										{!!filterCustom.name.length && <i className="fal fa-times-circle" />}
									</button>
								}
								name="username"
								defaultValue={filterCustom.name}
								onChange={handleChangeSearch}
								placeholder="Search"
								labelPosition="right"
								inputRef={inputRef}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>

			<div className="tabs-view">
				<Tab panes={panes} />
			</div>
		</div>
	)
}

AddCourses.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	customFilter: PropTypes.object.isRequired,
}

export default withContext(
	([
		{
			users: { customFilter },
		},
	]) => ({
		// state
		customFilter,
	}),
	withBreadcrumbs(AddCourses),
)
