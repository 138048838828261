import React, { useState, useCallback, useEffect } from 'react'
import { Loader, Grid, Button, Confirm } from 'semantic-ui-react'
import { CustomDropdown } from 'Common'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Routes } from 'Constants'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { withContext } from 'Context'
import { SESSION_STATUS_UPDATE, DELETE_SESSIONS } from 'Mutations/settingMutations'
import { ACCOUNT_SESSION_LIST } from 'Queries/settingQueries'
import { getFilteredSettings } from 'Actions/usersActions'
import SendEmail from 'Modals/session/SendEmail'
import { withToastManager } from 'react-toast-notifications'
import SessionsTable from './SessionsTable'

const dropdownOptions = [
	{
		key: 'active',
		text: 'Active',
		value: 1,
	},
	// {
	// 	key: 'pause',
	// 	text: 'Pause',
	// 	value: 2,
	// },
	{
		key: 'complete',
		text: 'Complete',
		value: 3,
	},
	{
		key: 'copy',
		text: 'Copy',
		value: 4,
	},
	{
		key: 'Notify_learner',
		text: 'Notify learner',
		value: 5,
	},
	{
		key: 'delete',
		text: 'Delete',
		value: 6,
	},
]

const confirmInitial = {
	open: false,
	confirmed: false,
}

const SessionList = ({ customFilter, onFilter, toastManager }) => {
	const history = useHistory()
	const [openEmailModal, setOpenEmailModal] = useState(false)
	const [sessionList, setSessionList] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [selectedTopic, setselectedTopic] = useState(null)
	const [dropdownValue, setDropdownValue] = useState(null)
	const [activeActionsSelect, setActiveActionsSelect] = useState(true)
	const [confirmModal, setConfirmModal] = useState(confirmInitial)
	const [confirmModalLoading, setConfirmModalLoading] = useState(false)
	const [updateStatusMutation] = useMutation(SESSION_STATUS_UPDATE)
	const [deleteSessionMutation] = useMutation(DELETE_SESSIONS)

	const actions = {
		1: {
			params: 1,
			modalSize: 'mini',
			content: 'Are you sure, You want to start?',
		},
		// 2: {
		// 	params: 2,
		// 	modalSize: 'mini',
		// 	content: 'Are you sure, You want to pause?',
		// },
		3: {
			params: 3,
			modalSize: 'mini',
			content: 'Are you sure, You want to close?',
		},
		4: {
			params: 0,
			modalSize: 'mini',
			content: 'Are you sure, You want to copy?',
		},
		6: {
			params: 4,
			modalSize: 'mini',
			content: 'Are you sure, You want to delete?',
		},
	}

	const { data: sessionsData, error, loading, refetch: sessionRefetch } = useQuery(ACCOUNT_SESSION_LIST, {
		variables: {
			filters: {
				title: customFilter.username,
				page: customFilter.page,
				perPage: 15,
				sort_param: customFilter.sort_param,
				sort_direction: customFilter.sort_direction,
				tag_id: customFilter.tag_id,
				venue: customFilter.venue,
				status: customFilter.status,
			},
		},
		fetchPolicy: 'network-only',
	})

	const handleSetPage = useCallback(
		page => {
			onFilter({ page })
		},
		[onFilter],
	)

	useEffect(() => {
		if (sessionsData) setSessionList(sessionsData.accountSessions.data.data)
	}, [sessionsData])

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)
			const newActiveParam = { name: customFilter.sort_param, order: customFilter.sort_direction }
			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter({ sort_param: newActiveParam.name, sort_direction: newActiveParam.order, page: 1 })
		},
		[onFilter, customFilter],
	)

	useEffect(() => {
		if (!!selectedUsers.length && activeActionsSelect) setActiveActionsSelect(false)
		if (!selectedUsers.length && !activeActionsSelect) setActiveActionsSelect(true)
	}, [selectedUsers])

	useEffect(() => {
		if (dropdownValue && dropdownValue == 4 && selectedUsers.length === 1) {
			// setConfirmModal({ ...confirmInitial, open: true })
			confirmModalConfirmHandler()
		} else if (dropdownValue && dropdownValue == 4) {
			toastManager.add('Select only one session to copy.', { appearance: 'error', autoDismiss: true })
			confirmModalCancelHandler()
		}
		if (dropdownValue && dropdownValue != 4 && dropdownValue != 5)
			setConfirmModal({ ...confirmInitial, open: true })
		if (dropdownValue && dropdownValue == 5) openEmailModalHandler()
	}, [dropdownValue])

	const confirmModalCancelHandler = () => {
		setConfirmModal(confirmInitial)
		setDropdownValue(null)
	}

	const confirmModalConfirmHandler = () => {
		if (actions[dropdownValue].params == 0) {
			history.push({
				pathname: `${Routes.SESSION.path}/${selectedTopic}/copy/${selectedUsers[0]}`,
				state: { mode: 'copy' },
			})
		} else if (actions[dropdownValue].params == 4) {
			setConfirmModalLoading(true)
			deleteSessionMutation({
				variables: {
					session_ids: selectedUsers,
				},
			})
				.then(({ data }) => {
					if (data.deleteSession) {
						setConfirmModal({ open: false, confirmed: true })
						setDropdownValue(null)
						setConfirmModalLoading(false)
						setSelectedUsers([])
						sessionRefetch()
						toastManager.add('Delete Successfully.', { appearance: 'success', autoDismiss: true })
					}
				})
				.catch(error => {
					console.log('value in error', error)
				})
				.finally(() => {
					setConfirmModal({ open: false, confirmed: true })
					setDropdownValue(null)
					setSelectedUsers([])
					setConfirmModalLoading(false)
				})
		} else {
			setConfirmModalLoading(true)
			updateStatusMutation({
				variables: {
					session_ids: selectedUsers,
					status: actions[dropdownValue].params,
				},
			})
				.then(({ data }) => {
					if (data.statusChange) {
						setConfirmModal({ open: false, confirmed: true })
						setDropdownValue(null)
						setConfirmModalLoading(false)
						setSelectedUsers([])
						sessionRefetch()
						toastManager.add('Action updated.', { appearance: 'success', autoDismiss: true })
					}
				})
				.catch(error => {
					console.log('value in error', error)
				})
				.finally(() => {
					setConfirmModal({ open: false, confirmed: true })
					setDropdownValue(null)
					setSelectedUsers([])
					setConfirmModalLoading(false)
				})
		}
	}

	const openEmailModalHandler = () => {
		setOpenEmailModal(true)
	}

	const createEmailModalCloseHandler = () => {
		setOpenEmailModal(false)
		setConfirmModal(confirmInitial)
		setDropdownValue(null)
	}

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	return (
		<>
			<div className="users-list">
				<Grid>
					<Grid.Row className="stackable">
						<Grid.Column width={8}>
							<h2>Sessions</h2>
						</Grid.Column>
						<Grid.Column width={4} />
						<Grid.Column width={4}>
							<div className="session-dropdown">
								<CustomDropdown
									selection
									icon="angle down"
									placeholder="Actions"
									value={dropdownValue}
									options={dropdownOptions}
									selectOnBlur={false}
									disabled={activeActionsSelect}
									onChange={(e, { value }) => setDropdownValue(value)}
								/>
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<div className="page-title all-topic-table" style={{ marginTop: '50px' }}>
					<SessionsTable
						sessionList={sessionList}
						page={sessionsData.accountSessions.data.current_page}
						perPage={sessionsData.accountSessions.data.per_page}
						totalCount={sessionsData.accountSessions.data.total}
						length={sessionsData.accountSessions.data.total}
						setPage={handleSetPage}
						handleSortTable={handleSortTable}
						selectedUsers={selectedUsers}
						setSelectedUsers={setSelectedUsers}
						setselectedTopic={setselectedTopic}
						activeSortParam={{
							name: customFilter.sort_param || '',
							order: customFilter.sort_direction || 'asc',
						}}
					/>
				</div>

				<Confirm
					open={confirmModal.open}
					size={actions[dropdownValue] ? actions[dropdownValue].modalSize : 'mini'}
					content={actions[dropdownValue] ? actions[dropdownValue].content : ''}
					cancelButton={<Button className="reverse" disabled={confirmModalLoading} content="Cancel" />}
					confirmButton={
						<Button loading={confirmModalLoading} disabled={confirmModalLoading} content="Confirm" />
					}
					onCancel={confirmModalCancelHandler}
					onConfirm={confirmModalConfirmHandler}
				/>
				{openEmailModal && (
					<SendEmail
						open={openEmailModal}
						onClose={createEmailModalCloseHandler}
						selectedUsersId={selectedUsers}
						type="session"
					/>
				)}
			</div>
		</>
	)
}

SessionList.propTypes = {
	customFilter: PropTypes.object.isRequired,
	toastManager: PropTypes.object.isRequired,
	onFilter: PropTypes.func,
}

SessionList.defaultProps = {
	onFilter: () => {},
}

export default withContext(
	([
		{
			users: { customFilter },
		},
		dispatch,
	]) => ({
		// state
		customFilter,
		// Actions
		onFilter: data => getFilteredSettings(data, dispatch),
	}),
	withToastManager(SessionList),
)
