import gql from 'graphql-tag'

export const GET_SUBJECT_SECTION_BY_ID = gql`
	query subjectSection($id: Int!, $pivot_id: Int!, $limit: Int, $user_id: Int) {
		byId(id: $id, limit: $limit, user_id: $user_id, pivot_id: $pivot_id) {
			key
			id
			subject_id
			name
			description
			img
			force_learning
			outcomes_remaining
			completeOutcomes
			countOutcomeQuestions
			countOutcomeQuestionAnswers
			subject {
				id
				auto_sign_off_enabled
				name
				default_force_learning
			}
			firstAssessment {
				id
				created_at
			}
			assessment {
				id
				retake
			}
			type
			retake
			is_start
			is_completed
			progress
			scorm_course_id
			obs_module_id
			user_subject_section_id
			obs_learning_stats
			total_time_tracked
			date_completed
			outcomesLearningLinkCount
			outcomes {
				id
				learning_link
			}
			allOutcomes {
				id
				learning_link
				lastActive {
					id
					created_at
				}
			}
		}
	}
`
