import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'

import { GET_USER_CARD_INFO } from 'Queries/usersQueries'

import { UserPhoto } from 'Common'
import './style.scss'

const UserCard = ({ match, setUser }) => {
	const {
		params: { userId },
	} = match
	const { loading, error, data } = useQuery(GET_USER_CARD_INFO, {
		variables: { id: userId },
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (setUser && !loading) {
			setUser(data.byId)
		}
	}, [data, setUser, loading])

	if (loading || error) return null

	return (
		<div className="subject-user-card">
			<UserPhoto className="photo" user={data.byId} size="mini" />
			<span>{`${data.byId.first_name} ${data.byId.last_name}`}</span>
		</div>
	)
}

UserCard.defaultProps = {
	setUser: null,
}

UserCard.propTypes = {
	match: PropTypes.object.isRequired,
	setUser: PropTypes.func,
}

export default withRouter(UserCard)
