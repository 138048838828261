import React, { useState, useEffect } from 'react'
import moment from 'moment'
import _ from 'lodash'
import PropTypes from 'prop-types'
import FormComponent from 'Helpers/form'
import { Button, Icon } from 'semantic-ui-react'
import { useForm } from 'Hooks/useForm'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_EVIDENCE } from 'Mutations/evidenceMutations'
import { GET_SUBJECT_SECTION_EVIDENCES } from 'Queries/evidenceQueries'
import { withRouter } from 'react-router-dom'
import { withContext } from '../../../../context'

const validate = values => {
	const errors = {}

	const requiredFields = ['evidenceText']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	return errors
}

const EvidenceItem = ({
	id,
	created_at,
	user,
	outcomes,
	evidence,
	subject_user_id,
	sign_off,
	type: { name: typeName },
	authUser,
	isManager,
	sectionId,
	pivotId,
	history,
	match,
	isPdfExport,
	autoSignEnabled,
	session_id,
	user_sessions_id,
	session_user_id,
}) => {
	const [viewMore, setViewMore] = useState(false)
	const [editMode, setEditMode] = useState(false)
	const [saving, setSaving] = useState(false)
	const [evidenceType, setEvidenceType] = useState(false)

	const formattedDate = moment(created_at).format('DD/MM/YYYY')

	const [updateEvidence] = useMutation(UPDATE_EVIDENCE, {
		refetchQueries: [
			{
				query: GET_SUBJECT_SECTION_EVIDENCES,
				variables: {
					pivot_id: String(pivotId),
					subject_section_id: String(sectionId),
					user_id: String(isManager ? subject_user_id : authUser.id),
				},
			},
		],
	})

	const {
		values: { evidenceText },
		errors,
		handleChange,
		handleSubmit,
	} = useForm(
		() => {
			setSaving(true)

			updateEvidence({
				variables: {
					evidence: evidenceText,
					evidence_id: id,
				},
			})
				.then(() => {
					setEditMode(false)
				})
				.finally(() => {
					setSaving(false)
				})
		},
		validate,
		{
			evidenceText: evidence,
		},
	)

	const evidenceView = () => {
		if (editMode)
			return (
				<>
					<FormComponent.Textarea
						name="evidenceText"
						value={evidenceText}
						rows={2}
						onChange={handleChange}
						fluid
						error={errors.evidence}
					/>
					<Button
						type="button"
						size="medium"
						content="Save"
						loading={saving}
						disabled={saving}
						onClick={handleSubmit}
					/>
				</>
			)

		switch (typeName) {
			case 'Results PDF':
				return <Button size="large" content="View PDF" onClick={() => window.open(evidence)} />
			case 'Learning Archive':
				return (
					<Button
						size="large"
						content="View Learning Archive"
						onClick={() => history.push(match.url + '/learning-archive')}
					/>
				)
			default:
				return (
					<p>
						{/* eslint-disable-next-line max-len */}
						{!isPdfExport && evidence.length > 130 && !viewMore
							? `${evidence.slice(0, 130)}...`
							: evidence}{' '}
						{!isPdfExport && evidence.length > 130 && (
							<button
								type="button"
								className="view-more blue-text"
								onClick={viewMore ? () => setViewMore(false) : () => setViewMore(true)}
							>{`view ${viewMore ? 'less' : 'more'}`}</button>
						)}
					</p>
				)
		}
	}

	useEffect(() => {
		if (subject_user_id == null) {
			setEvidenceType('Session')
		} else {
			setEvidenceType(typeName)
		}
	}, [])

	return (
		<div id={`evidence-${id}`} className="evidence-item">
			<div className="top-items">
				{evidenceType && (
					<>
						<span className="blue-text">
							{evidenceType === 'It depends' && autoSignEnabled ? 'Note' : evidenceType}
						</span>
					</>
				)}
				<span className="dark-text">
					{user &&
						`Add ${sign_off ? '& sign off' : ''} by ${user.first_name} ${
							user.last_name
							// eslint-disable-next-line no-mixed-spaces-and-tabs
						} on ${formattedDate}`}
				</span>
				<span className="references">
					<span className="custom-label">Ref</span>
					{_.map(outcomes, (outcome, index) => (
						<span className="dark-text" key={outcome.id}>
							{outcome.reference}
							{index !== outcomes.length - 1 ? ', ' : ''}
						</span>
					))}
				</span>
				{evidenceType != 'Session' && (
					<>
						{user && authUser.id === user.id && (
							<Icon name="edit outline" onClick={() => setEditMode(true)} />
						)}
					</>
				)}
				{evidenceType == 'Session' && (
					<div className="session-evidence-icon">
						<Icon
							name="linkify"
							color="black"
							size="big"
							onClick={() => {
								history.push(
									'/learners/' + session_user_id + '/session/' + session_id + '/' + user_sessions_id,
								)
							}}
						/>
					</div>
				)}
			</div>
			{evidenceView()}
		</div>
	)
}

EvidenceItem.propTypes = {
	id: PropTypes.number.isRequired,
	created_at: PropTypes.string.isRequired,
	user: PropTypes.object,
	outcomes: PropTypes.arrayOf(PropTypes.object).isRequired,
	evidence: PropTypes.string,
	type: PropTypes.object.isRequired,
	authUser: PropTypes.object.isRequired,
	sign_off: PropTypes.bool.isRequired,
	isManager: PropTypes.bool.isRequired,
	sectionId: PropTypes.number.isRequired,
	pivotId: PropTypes.number.isRequired,
	subject_user_id: PropTypes.number.isRequired,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	isPdfExport: PropTypes.bool.isRequired,
	autoSignEnabled: PropTypes.bool,
	session_id: PropTypes.number,
	user_sessions_id: PropTypes.number,
	session_user_id: PropTypes.number,
}

EvidenceItem.defaultProps = {
	user: null,
	evidence: '',
	autoSignEnabled: false,
	session_id: null,
	user_sessions_id: null,
	session_user_id: null,
}

export default withRouter(
	withContext(
		([
			{
				user: { user },
			},
		]) => ({ authUser: user }),
		EvidenceItem,
	),
)
