import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'

import { withContext } from 'Context'
import { GET_USER_INFO } from 'Queries/dashboardQueries'
import { allRoles, isLearnerRole } from 'Utils/roles'

import Learner from './Learner'
import Manager from './Manager'

import './style.scss'

const Dashboard = ({ userRole, userId, userRoleType }) => {
	const { loading, error, data } = useQuery(GET_USER_INFO, {
		variables: { id: userId },
		fetchPolicy: 'network-only',
	})

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const {
		currentAccount: { is_account_owner, is_zoho_connected },
		groups,
		lastViewedSubject,
	} = data.byId

	return isLearnerRole(userRole, userRoleType) ? (
		<Learner groups={groups} subject={lastViewedSubject} />
	) : (
		<Manager isAccountOwner={is_account_owner} isZohoConnected={is_zoho_connected} userId={userId} />
	)
}

Dashboard.propTypes = {
	userId: PropTypes.number.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withContext(
	([
		{
			user: {
				user: {
					id,
					account: { role, roleType },
				},
			},
		},
	]) => ({
		// State
		userId: id,
		userRole: role,
		userRoleType: roleType,
	}),
	Dashboard,
)
