import { useState } from 'react'
import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_AVAILABLE_ACCOUNTS } from 'Queries/usersQueries'
import { CHANGE_ACCOUNT } from 'Mutations/usersMutations'
import { client } from '../store'

export default function useAccounts({ user, updateProfile, appLoadingSwitch }) {
	const [changeAccountLoading, setChangeAccountLoading] = useState(false)

	const availableAccounts = useQuery(GET_AVAILABLE_ACCOUNTS, {
		skip: !user,
		variables: { id: user && user.id },
		fetchPolicy: 'network-only',
	})

	const getAccounts = () => _.get(availableAccounts.data, 'byId.accounts') || []

	const [changeAccount] = useMutation(CHANGE_ACCOUNT)

	const getCurrentAccount = () => getAccounts().find(account => account.id === user.account.id)

	const handleAccountChange = ({ accountId, onSuccess }) => {
		setChangeAccountLoading(true)

		return changeAccount({ variables: { account_id: Number(accountId) } })
			.then(async response => {
				await onSuccess(response)

				return response
			})
			.then(({ data: { changeAccount } }) => {
				if (!changeAccount) {
					return
				}

				client.resetStore()
				updateProfile({
					...user,
					account: changeAccount,
				})
				appLoadingSwitch()

				setTimeout(appLoadingSwitch)
			})
			.finally(() => setChangeAccountLoading(false))
	}

	return {
		...availableAccounts,
		changeAccountLoading,
		getAccounts,
		getCurrentAccount,
		changeAccount: handleAccountChange,
	}
}
