import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Button } from 'semantic-ui-react'

import { useForm } from 'Hooks/useForm'

import FormComponent from 'Helpers/form'
import { ALLOCATE_ASSESSOR } from 'Mutations/usersMutations'

const validate = values => {
	const errors = {}

	if (!values.assessor_email || !/\S+@\S+\.\S+/.test(values.assessor_email)) errors.assessor_email = true

	return errors
}

const AllocateAssessor = ({ match, open, onClose }) => {
	const [loading, setLoading] = useState(false)

	const {
		params: { id },
	} = match

	const [allocateAssessor] = useMutation(ALLOCATE_ASSESSOR)

	const {
		values: { assessor_email },
		errors,
		handleSubmit,
		handleChange,
	} = useForm(() => {
		setLoading(true)

		allocateAssessor({
			variables: {
				learner_id: id,
				assessor_email,
			},
		}).finally(() => {
			setLoading(false)
			onClose()
		})
	}, validate)

	return (
		<Modal className="form-modal" open={open} size="mini" onClose={onClose}>
			<Modal.Header>Allocate assessor</Modal.Header>
			<Modal.Content>
				<form onSubmit={handleSubmit}>
					<FormComponent
						customlabel="Assessor email"
						name="assessor_email"
						onChange={handleChange}
						error={errors.assessor_email}
					/>

					<div className="actions text-right">
						<Button type="submit" content="Allocate" loading={loading} disabled={loading} />
						<Button
							className="reverse"
							type="button"
							content="Cancel"
							disabled={loading}
							onClick={onClose}
						/>
					</div>
				</form>
			</Modal.Content>
		</Modal>
	)
}

AllocateAssessor.propTypes = {
	match: PropTypes.object.isRequired,
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
}

AllocateAssessor.defaultProps = {
	open: false,
}

export default withRouter(AllocateAssessor)
