import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'

import { withContext } from 'Context'
import { UPDATE_PROFILE_MUTATION } from 'Mutations/profileMutations'
import { profileUpdateAction } from 'Actions/profileActions'
import { useForm } from 'Hooks'
import FormComponent from 'Helpers/form'
import { PageMetaTags, UserPhoto } from 'Common'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import './style.scss'

const validate = values => {
	const errors = {}
	const requiredFields = ['first_name', 'last_name']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	if (values.password && values.password.length < 8) errors.password = true
	if (values.password !== values.password_confirmation) errors.password_confirmation = true

	return errors
}

const PAGE_NAME = 'My Profile'

const UpdateUserInfoForm = ({ user, updateProfileSuccess, setBreadcrumbs }) => {
	const [updateInProgress, setUpdateInProgress] = useState(false)

	useEffect(() => {
		setBreadcrumbs([
			{
				name: PAGE_NAME,
			},
		])
	}, [])

	const [updateProfileRequest] = useMutation(UPDATE_PROFILE_MUTATION)

	const {
		values: { photo, first_name, last_name, password, email, secondary_email, password_confirmation },
		errors,
		handleSubmit,
		handleChange,
		handleImageChange,
	} = useForm(
		() => {
			setUpdateInProgress(true)

			updateProfileRequest({
				variables: {
					id: user.id,
					first_name,
					last_name,
					email: user.email,
					secondary_email: secondary_email,
					password,
					password_confirmation,
					photo: photo ? photo.file : undefined,
				},
			})
				.then(({ data: { updateProfile } }) => {
					if (updateProfile) {
						updateProfileSuccess({
							...user,
							first_name,
							last_name,
							secondary_email,
							photo: updateProfile.photo,
						})
					}
				})
				.finally(() => {
					setUpdateInProgress(false)
				})
		},
		validate,
		{
			first_name: user.first_name,
			last_name: user.last_name,
			email: user.email,
			secondary_email: user.secondary_email,
		},
	)

	return (
		<div className="user-info-form">
			<PageMetaTags title={PAGE_NAME} />
			<form onSubmit={handleSubmit}>
				<div className="photo-wrap text-center">
					<label htmlFor="user-photo">
						<input
							name="photo"
							className="hidden"
							id="user-photo"
							type="file"
							onChange={handleImageChange}
						/>
						<UserPhoto size="small" user={user} src={photo ? photo.url : user.photo} />
					</label>
				</div>
				<FormComponent
					customlabel="First name"
					name="first_name"
					value={first_name || ''}
					onChange={handleChange}
					placeholder="Enter first name"
					size="large"
					error={errors.first_name}
				/>
				<FormComponent
					customlabel="Last name"
					name="last_name"
					value={last_name || ''}
					onChange={handleChange}
					placeholder="Enter last name"
					size="large"
					error={errors.last_name}
				/>
				<FormComponent
					customlabel="Email"
					name="email"
					value={email || ''}
					onChange={handleChange}
					placeholder="Enter email"
					size="large"
					disabled
					error={errors.email}
				/>
				<FormComponent
					customlabel="Secondary Email"
					name="secondary_email"
					value={secondary_email || ''}
					onChange={handleChange}
					placeholder="Enter secondary email"
					size="large"
					error={errors.secondary_email}
				/>
				<FormComponent.Password
					label="New password"
					name="password"
					value={password || ''}
					onChange={handleChange}
					placeholder="Enter new password"
					size="large"
					error={errors.password}
				/>
				<FormComponent.Password
					label="Confirm Password"
					name="password_confirmation"
					value={password_confirmation || ''}
					onChange={handleChange}
					placeholder="Confirm Password"
					size="large"
					error={errors.password_confirmation}
				/>
				<div className="user-info-form__actions">
					<Button size="large" loading={updateInProgress} disabled={updateInProgress} content="Update" />
				</div>
			</form>
		</div>
	)
}

UpdateUserInfoForm.propTypes = {
	user: PropTypes.object.isRequired,
	updateProfileSuccess: PropTypes.func.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
}

export default withBreadcrumbs(
	withContext(
		([
			{
				user: { user },
			},
			dispatch,
		]) => ({
			// state
			user,
			// actions
			updateProfileSuccess: data => profileUpdateAction(data, dispatch),
		}),
		UpdateUserInfoForm,
	),
)
