import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Loader, Button, Grid, Confirm } from 'semantic-ui-react'
import { CustomDropdown } from 'Common'
import PropTypes from 'prop-types'
import './style.scss'
import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Routes } from 'Constants'
import { withContext } from 'Context'
import { GET_ALL_TOPICS } from 'Queries/settingQueries'
import { DELETE_TOPICS } from 'Mutations/settingMutations'
import { getFilteredSettings } from 'Actions/usersActions'
import { withToastManager } from 'react-toast-notifications'
import TopicsTable from './TopicsTable'
import ImportSession from '../../../modals/session/ImportSession'
import ImportBulkSession from '../../../modals/session/ImportSession/ImportBulkSession'

const dropdownOptions = [
	{
		key: 'delete',
		text: 'Delete',
		value: 1,
	},
]

const confirmInitial = {
	open: false,
	confirmed: false,
}

const SubjectList = ({ customFilter, onFilter, toastManager }) => {
	const history = useHistory()
	const [topicsList, setTopicsdata] = useState([])
	const [selectedUsers, setSelectedUsers] = useState([])
	const [activeActionsSelect, setActiveActionsSelect] = useState(true)
	const [dropdownValue, setDropdownValue] = useState(null)
	const [confirmModal, setConfirmModal] = useState(confirmInitial)
	const [confirmModalLoading, setConfirmModalLoading] = useState(false)
	const [deleteTopicMutation] = useMutation(DELETE_TOPICS)
	const [openImportModal, setOpenImportModal] = useState(false)
	const [openImportBulkSessionModal, setOpenImportBulkSessionModal] = useState(false)
	const createSessionImportModalCloseHandler = () => {
		setOpenImportModal(false)
		//window.location.reload()
	}
	const createBulkSessionImportModalCloseHandler = () => {
		setOpenImportBulkSessionModal(false)
	}
	console.log(openImportModal)
	const actions = {
		1: {
			params: 1,
			modalSize: 'mini',
			content: 'Are you sure, You want to delete?',
		},
	}

	const { data: topicsData, error, loading, refetch: topicRefetch } = useQuery(GET_ALL_TOPICS, {
		variables: {
			filters: {
				name: customFilter.username,
				page: customFilter.page,
				perPage: 15,
				sort_param: customFilter.sort_param,
				sort_direction: customFilter.sort_direction,
				tag_id: customFilter.tag_id,
				is_enabled: customFilter.status,
			},
		},
		fetchPolicy: 'network-only',
	})

	const handleSetPage = useCallback(
		page => {
			onFilter({ page })
		},
		[onFilter],
	)

	useEffect(() => {
		if (topicsData) setTopicsdata(topicsData.accountTopics.data.data)
	}, [topicsData])

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)
			const newActiveParam = { name: customFilter.sort_param, order: customFilter.sort_direction }
			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter({ sort_param: newActiveParam.name, sort_direction: newActiveParam.order, page: 1 })
		},
		[onFilter, customFilter],
	)

	useEffect(() => {
		if (!!selectedUsers.length && activeActionsSelect) setActiveActionsSelect(false)
		if (!selectedUsers.length && !activeActionsSelect) setActiveActionsSelect(true)
	}, [selectedUsers])

	useEffect(() => {
		if (dropdownValue) setConfirmModal({ ...confirmInitial, open: true })
	}, [dropdownValue])

	const confirmModalCancelHandler = () => {
		setConfirmModal(confirmInitial)
		setDropdownValue(null)
	}

	const confirmModalConfirmHandler = () => {
		setConfirmModalLoading(true)
		deleteTopicMutation({
			variables: {
				topic_ids: selectedUsers,
			},
		})
			.then(({ data }) => {
				if (data.deleteTopic) {
					setConfirmModal({ open: false, confirmed: true })
					setDropdownValue(null)
					setConfirmModalLoading(false)
					setSelectedUsers([])
					topicRefetch()
					toastManager.add('Delete Successfully.', { appearance: 'success', autoDismiss: true })
				}
			})
			.catch(error => {
				console.log('value in error', error)
			})
			.finally(() => {
				setConfirmModal({ open: false, confirmed: true })
				setDropdownValue(null)
				setSelectedUsers([])
				setConfirmModalLoading(false)
			})
	}

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>
	return (
		<>
			<div className="users-list">
				<Grid>
					<Grid.Row className="stackable session-stack-gap">
						<Grid.Column className="session-actions">
							<h2>Topics</h2>
						</Grid.Column>

						<Grid.Column className="session-actions">
							<div className="session-dropdown">
								<CustomDropdown
									selection
									icon="angle down"
									placeholder="Actions"
									value={dropdownValue}
									options={dropdownOptions}
									selectOnBlur={false}
									fluid
									disabled={activeActionsSelect}
									onChange={(e, { value }) => setDropdownValue(value)}
								/>
							</div>
						</Grid.Column>
						<Grid.Column className="session-btn-width">
							<Button
								type="button"
								size="big"
								fluid
								floated="right"
								content="Import Sessions"
								onClick={() => setOpenImportModal(true)}
							/>
						</Grid.Column>
						<Grid.Column className="session-btn-width">
							<Button
								size="big"
								floated="right"
								fluid
								content="View all Sessions"
								onClick={() => {
									history.push(Routes.SESSION.path)
								}}
							/>
						</Grid.Column>
						<Grid.Column className="session-btn-width">
							<Button
								size="big"
								onClick={() => {
									history.push(Routes.CREATE_TOPICS)
								}}
								fluid
								content="Create Topics"
							/>
						</Grid.Column>
						<Grid.Column className="session-btn-width">
							<Button
								size="big"
								onClick={() => setOpenImportBulkSessionModal(true)}
								fluid
								content="Import Bulk Sessions"
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>

				<div className="page-title all-topic-table" style={{ marginTop: '50px' }}>
					<TopicsTable
						topicsList={topicsList}
						page={topicsData.accountTopics.data.current_page}
						perPage={topicsData.accountTopics.data.per_page}
						totalCount={topicsData.accountTopics.data.total}
						length={topicsData.accountTopics.data.total}
						setPage={handleSetPage}
						handleSortTable={handleSortTable}
						activeSortParam={{
							name: customFilter.sort_param || '',
							order: customFilter.sort_direction || 'asc',
						}}
						selectedUsers={selectedUsers}
						setSelectedUsers={setSelectedUsers}
					/>
				</div>
				<Confirm
					open={confirmModal.open}
					size={actions[dropdownValue] ? actions[dropdownValue].modalSize : 'mini'}
					content={actions[dropdownValue] ? actions[dropdownValue].content : ''}
					cancelButton={<Button className="reverse" disabled={confirmModalLoading} content="Cancel" />}
					confirmButton={
						<Button loading={confirmModalLoading} disabled={confirmModalLoading} content="Confirm" />
					}
					onCancel={confirmModalCancelHandler}
					onConfirm={confirmModalConfirmHandler}
				/>
			</div>
			<ImportSession open={openImportModal} onClose={createSessionImportModalCloseHandler} />
			<ImportBulkSession open={openImportBulkSessionModal} onClose={createBulkSessionImportModalCloseHandler} />
		</>
	)
}

SubjectList.propTypes = {
	customFilter: PropTypes.object.isRequired,
	onFilter: PropTypes.func,
	toastManager: PropTypes.object.isRequired,
}

SubjectList.defaultProps = {
	onFilter: () => {},
}

export default withContext(
	([
		{
			users: { customFilter },
		},
		dispatch,
	]) => ({
		// state
		customFilter,
		// Actions
		onFilter: data => getFilteredSettings(data, dispatch),
	}),
	withToastManager(SubjectList),
)
