import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Loader, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { PageContent } from 'Common'
import _ from 'lodash'
import { ADD_SESSION_TO_LEARNER } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'
import { Routes } from 'Constants'
import { withContext } from 'Context'
import { getFilteredSettings } from 'Actions/usersActions'
import moment from 'moment'
import SessionTable from './SessionTable'

const SessionList = ({ customFilter, onFilter, learnerDetails, toastManager, sessionData, sessionLoading }) => {
	const history = useHistory()
	const [selectedSessions, setSelectedSessions] = useState([])
	const [sessionList, setSessionList] = useState([])
	const [sessionToLearner] = useMutation(ADD_SESSION_TO_LEARNER)
	const [stateloading] = useState(false)
	const [loader, setLoader] = useState(false)

	const handleSessionsChange = (index, e, { name, checked, value = checked }) => {
		const newSessions = _.cloneDeep(sessionList)
		newSessions[index][name] = value
		setSessionList(newSessions)
	}

	const handleSetPage = useCallback(
		page => {
			onFilter({ page })
		},
		[onFilter],
	)

	useEffect(() => {
		if (sessionData) {
			let tempArr = sessionData.accountSessions.data.data.map(d => ({ ...d, listType: 'session' }))
			tempArr = tempArr.filter(data => !(data.status === 5 && data.parent_session_id !== null))
			setSessionList(tempArr)
		}
	}, [sessionData])

	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)
			const newActiveParam = { name: customFilter.sort_param, order: customFilter.sort_direction }
			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter({ sort_param: newActiveParam.name, sort_direction: newActiveParam.order, page: 1 })
		},
		[onFilter, customFilter],
	)

	const submitCoursesMutation = () => {
		if (learnerDetails.selectedUsers) {
			const isSelectedAll = learnerDetails.selectedUsers === 'all'
			let selectedFinalSession = sessionList.filter(o1 => selectedSessions.some(o2 => o1.id === o2.id))
			let tempSelectedSession = selectedFinalSession.map(item => {
				return {
					id: item.id,
					target_completion: item.date
						? moment(item.date, 'YYYY-MM-DD', true).isValid()
							? item.date
							: moment(moment(item.date, 'DD-MM-YYYY')).format('YYYY-MM-DD')
						: item.date,
					refresh_period: item.default_refresher_period,
				}
			})
			if (tempSelectedSession.length > 0) {
				setLoader(true)
				sessionToLearner({
					variables: {
						actions: {
							sessions_id: tempSelectedSession,
							users_id: isSelectedAll ? null : learnerDetails.selectedUsers,
							all: isSelectedAll,
						},
						filters: {
							username: '',
							role: '',
							group: '',
							subject: '',
							status_evidence: '',
							active: '',
							page: 1,
						},
					},
				})
					.then(({ data }) => {
						setLoader(false)
						if (data.bookLearners) {
							toastManager.add('Session Added Successfully', {
								appearance: 'success',
								autoDismiss: true,
							})
							setSelectedSessions([])
							history.push(`${Routes.USERS}`)
						}
					})
					.catch(error => {
						console.log('value in error', error)
						setLoader(false)
						setSelectedSessions([])
					})
					.finally(() => {
						setLoader(false)
					})
			}
		} else {
			toastManager.add('Please choose the learner first.', { appearance: 'error', autoDismiss: true })
		}
		// if (learnerDetails.selectedUsers) {
		// 	let selectedFinalSession = sessionList.filter(o1 => selectedSessions.some(o2 => o1.id === o2.id))

		// 	let tempSelectedSession = selectedFinalSession.map(item => {
		// 		return {
		// 			id: item.id,
		// 			target_completion: item.date,
		// 		}
		// 	})

		// 	if (tempSelectedSession.length > 0) {
		// 		setLoader(true)
		// 		sessionToLearner({
		// 			variables: {
		// 				sessions: tempSelectedSession,
		// 				user_ids: learnerDetails.selectedUsers,
		// 			},
		// 		})
		// 			.then(({ data }) => {
		// 				setLoader(false)
		// 				if (data.bookLearners) {
		// 					toastManager.add('Session Added Successfully', {
		// 						appearance: 'success',
		// 						autoDismiss: false,
		// 					})
		// 					setSelectedSessions([])
		// 					history.push(`${Routes.USERS}`)
		// 				}
		// 			})
		// 			.catch(error => {
		// 				setLoader(false)
		// 				console.log('value in error', error)
		// 				setSelectedSessions([])
		// 			})
		// 			.finally(() => {
		// 				setLoader(false)
		// 			})
		// 	}
		// } else {
		// 	toastManager.add('Please choose the learner first.', { appearance: 'error', autoDismiss: false })
		// }
	}

	if (sessionLoading || loader) return <Loader active />
	if (!sessionList.length)
		return (
			<div className="msg-line">
				<PageContent.Message
					message="There are no sessions available.
				Please refer to the Help Centre for more information on sessions."
				/>
			</div>
		)

	return (
		<>
			<SessionTable
				sessionList={sessionList}
				setPage={handleSetPage}
				handleSortTable={handleSortTable}
				selectedSessions={selectedSessions}
				setSelectedSessions={setSelectedSessions}
				activeSortParam={{
					name: customFilter.sort_param || '',
					order: customFilter.sort_direction || 'asc',
				}}
				handleSessionsChange={handleSessionsChange}
			/>
			<div className="course-btn">
				<Button
					primary
					color="blue"
					content="Cancel"
					loading={stateloading}
					disabled={stateloading}
					onClick={() => {
						history.push(`${Routes.USERS}`)
					}}
				/>
				<Button
					primary
					color="blue"
					floated="right"
					content="Add Session"
					loading={stateloading}
					disabled={selectedSessions.length > 0 ? false : true}
					onClick={submitCoursesMutation}
				/>
			</div>
		</>
	)
}

SessionList.propTypes = {
	customFilter: PropTypes.object.isRequired,
	learnerDetails: PropTypes.object.isRequired,
	onFilter: PropTypes.func,
	toastManager: PropTypes.object.isRequired,
	sessionData: PropTypes.object.isRequired,
	sessionLoading: PropTypes.bool.isRequired,
}

SessionList.defaultProps = {
	onFilter: () => {},
}

export default withContext(
	([
		{
			users: { customFilter, learnerDetails },
		},
		dispatch,
	]) => ({
		// state
		customFilter,
		learnerDetails,
		// Actions
		onFilter: data => getFilteredSettings(data, dispatch),
	}),
	withToastManager(SessionList),
)
