import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Button, Icon, Loader } from 'semantic-ui-react'
import _ from 'lodash'
import scrollTo from 'animated-scroll-to'
import { withContext } from 'Context'
import { GET_SECTION_OUTCOMES_MANAGER, GET_SUBJECT_SECTION_BY_ID } from 'Queries/subjectsQueries'

// import { CustomDropdown, AccessibilityReadableModal } from 'Common'
import { CustomDropdown } from 'Common'
import OutcomesList from './OutcomesList'
import EvidencesList from './EvidencesList'
import LearningActivityList from './LearningActivityList'
import AddEvidencesForm from './AddEvidencesForm'
// import FunkyTumbModalContent from './FunkyTumb'
import SignOffSection from '../SignOffSection'
import './style.scss'

const dropdownOptions = [
	{
		key: 'attention',
		text: 'Showing outcomes requiring attention',
		value: 0,
	},
	{
		key: 'all',
		text: 'Showing all outcomes',
		value: 1,
	},
]

const ManageOutcomes = ({ match, user, manager, noSignOff, matchForExport, learning_stats }) => {
	const [selectedItems, setSelectedItems] = useState([])
	const [dropdownValue, setDropdownValue] = useState(JSON.parse(window.localStorage.getItem('outcomesFilter')))

	const {
		params: { pivotId, sectionId, userId },
	} = matchForExport ? matchForExport : match

	const { loading, error, data } = useQuery(GET_SECTION_OUTCOMES_MANAGER, {
		variables: {
			pivot_id: parseInt(pivotId),
			id: parseInt(sectionId),
			user_id: manager ? userId : user.id,
		},
		fetchPolicy: 'network-only',
	})

	const { data: subjectData, error: subjectError, loading: subjectLoading } = useQuery(GET_SUBJECT_SECTION_BY_ID, {
		variables: { id: parseInt(sectionId), pivot_id: parseInt(pivotId), limit: 1, user_id: parseInt(userId) },
		fetchPolicy: 'network-only',
	})

	const handleClickScroll = useCallback(() => {
		scrollTo(0, { elementToScroll: document.getElementById('pages-container') || window })
	}, [])

	useEffect(() => {
		window.localStorage.setItem('outcomesFilter', dropdownValue)
	}, [dropdownValue])

	if (loading || subjectLoading) {
		return (
			<div style={{ marginTop: '50px' }}>
				<Loader active inline="centered" />
			</div>
		)
	}
	if (error || subjectError) return null

	const { name, outcomes, type, verify, is_verified, is_imported, date_verified, verifiedBy } = data.byId

	const {
		subject: { auto_sign_off_enabled },
	} = subjectData.byId

	const outcomesList = _.orderBy(
		dropdownValue === 0
			? _.filter(
					outcomes,
					outcome =>
						outcome.status !== 'success' ||
						(outcome.observable && outcome.observationStatus !== 'observation_done'),
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  )
			: outcomes,
		[o => Number(o.reference.replace(/[^0-9]/g, ''))],
	)
	return (
		<div className="manage-outcomes">
			<div className="page-title">
				<div>
					<h2>Outcomes</h2>
					<p className="page-title-text">
						When you have completed your assessment review your answers below. Click on the{' '}
						<i className="fas fa-plus-square" /> to understand how your answers have been marked.{' '}
						{/* <AccessibilityReadableModal
							className="instructions-modal"
							size="tiny"
							trigger={<i className="fas fa-info-circle" />}
							closeIcon
						>
							{<FunkyTumbModalContent />}
						</AccessibilityReadableModal>{' '}
						to understand how your answers have been marked.{' '} */}
					</p>
				</div>

				<CustomDropdown
					selection
					value={dropdownValue === null ? 1 : dropdownValue}
					options={dropdownOptions}
					icon="angle down"
					onChange={(e, { value }) => setDropdownValue(value)}
				/>
			</div>

			<OutcomesList
				outcomes={outcomesList}
				subjectSectionType={type}
				selectedItems={selectedItems}
				setSelectedItems={setSelectedItems}
				manager={manager}
				isExport={!!matchForExport}
				learning_stats={learning_stats}
			/>

			<EvidencesList manager={manager} matchForExport={matchForExport} autoSignEnabled={auto_sign_off_enabled} />

			<LearningActivityList manager={manager} matchForExport={matchForExport} />

			{((verify && manager && !noSignOff) || is_verified) && (
				<>
					<h2>Sign Off {name}</h2>
					<SignOffSection
						is_imported={is_imported}
						is_verified={is_verified}
						date_verified={date_verified}
						verifiedBy={verifiedBy}
					/>
				</>
			)}
			<div className="scroll-top-wrap">
				<Button className="switch-role" icon onClick={handleClickScroll}>
					<Icon className="fas fa-angle-double-up" />
					Back to Top
				</Button>
			</div>

			{!_.isEmpty(selectedItems) && (
				<AddEvidencesForm selectedItems={selectedItems} manager={manager} setSelectedItems={setSelectedItems} />
			)}
		</div>
	)
}

ManageOutcomes.propTypes = {
	match: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	manager: PropTypes.bool.isRequired,
	noSignOff: PropTypes.bool.isRequired,
	matchForExport: PropTypes.object,
	learning_stats: PropTypes.object,
}

ManageOutcomes.defaultProps = {
	matchForExport: null,
	learning_stats: {},
}

export default withRouter(
	withContext(
		([
			{
				user: { user },
			},
		]) => ({ user }),
		ManageOutcomes,
	),
)
