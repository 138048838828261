import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { withToastManager } from 'react-toast-notifications'
import { Button } from 'semantic-ui-react'
import classNames from 'classnames'

import { useForm } from 'Hooks'
import FormComponent from 'Helpers/form'
import { withContext } from 'Context/index'

import { GET_SECTION_OUTCOMES_MANAGER } from 'Queries/subjectsQueries'
import { GET_SUBJECT_SECTION_EVIDENCES } from 'Queries/evidenceQueries'
import { ADD_EVIDENCE } from 'Mutations/evidenceMutations'
import { EvidenceType } from 'Common'
import { GET_SUBJECT_SECTION_BY_ID } from '../SubjectSectionInfo/queries'

const validate = values => {
	const errors = {}

	const requiredFields = ['evidence_type', 'evidence']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	return errors
}

const AddEvidencesForm = ({ match, selectedItems, setSelectedItems, toastManager, manager, user }) => {
	const [open, setOpen] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [signOff, setSignOff] = useState(false)

	const { sectionId, pivotId } = match.params
	let { userId } = match.params

	userId = manager ? userId : user.id

	const [createEvidence] = useMutation(ADD_EVIDENCE)

	const {
		values: { evidence_type, evidence },
		errors,
		handleSubmit,
		handleChange,
		handleSelectChange,
	} = useForm(() => {
		setSubmitting(true)

		createEvidence({
			variables: {
				user_id: userId,
				outcomes_id: selectedItems,
				evidence_type,
				evidence,
				sign_off: signOff,
				pivot_id: pivotId,
			},
			refetchQueries: [
				{
					query: GET_SECTION_OUTCOMES_MANAGER,
					variables: { id: parseInt(sectionId), user_id: userId, pivot_id: parseInt(pivotId) },
				},
				{
					query: GET_SUBJECT_SECTION_EVIDENCES,
					variables: {
						subject_section_id: String(sectionId),
						user_id: String(userId),
						pivot_id: String(pivotId),
					},
				},
				{
					query: GET_SUBJECT_SECTION_BY_ID,
					variables: {
						id: parseInt(sectionId),
						pivot_id: parseInt(pivotId),
						limit: 1,
						user_id: parseInt(userId),
					},
				},
			],
		})
			.then(() => {
				setSelectedItems([])
				toastManager.add('Successful', { appearance: 'success', autoDismiss: true })
			})
			.catch(() => {
				toastManager.add('Failed', { appearance: 'error' })
			})
			.finally(() => {
				setSubmitting(false)
			})
	}, validate)

	return (
		<div className="evidences-bottom-form">
			<div className="header">
				<span className="count-items">
					{`${selectedItems.length} outcome${selectedItems.length > 1 ? 's' : ''} selected`}
				</span>

				<Button
					className={classNames({ is_open: open })}
					size="small"
					content={open ? 'Close' : 'Add evidence'}
					onClick={() => setOpen(!open)}
				/>
			</div>

			{open && (
				<div className="container">
					<form onSubmit={handleSubmit}>
						<div className="form-fields">
							<EvidenceType
								evidenceType={evidence_type}
								handleSelectChange={handleSelectChange}
								error={errors.evidence_type}
								manager={manager}
							/>

							<FormComponent.Textarea
								label="Evidence"
								name="evidence"
								value={evidence}
								rows={6}
								onChange={handleChange}
								fluid
								error={errors.evidence}
							/>
						</div>

						<div className="actions text-right">
							<Button
								type="button"
								size="large"
								content="Add Evidence"
								loading={submitting && !signOff}
								disabled={submitting}
								onClick={() => {
									setSignOff(false)
									handleSubmit()
								}}
							/>

							{manager && (
								<Button
									type="button"
									size="large"
									content="Add & Sign Off"
									loading={submitting && signOff}
									disabled={submitting}
									onClick={() => {
										setSignOff(true)
										handleSubmit()
									}}
								/>
							)}
						</div>
					</form>
				</div>
			)}
		</div>
	)
}

AddEvidencesForm.propTypes = {
	match: PropTypes.object.isRequired,
	manager: PropTypes.bool.isRequired,
	toastManager: PropTypes.object.isRequired,
	selectedItems: PropTypes.array.isRequired,
	setSelectedItems: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
}

export default withRouter(
	withToastManager(
		withContext(
			([
				{
					user: { user },
				},
			]) => ({ user }),
			AddEvidencesForm,
		),
	),
)
