import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Modal, Loader, Icon, Popup } from 'semantic-ui-react'
import { useForm } from 'Hooks/useForm'
import { useMutation } from '@apollo/react-hooks'
import FormComponent from 'Helpers/form'
import { ADD_TASK } from 'Mutations/settingMutations'
import { withToastManager } from 'react-toast-notifications'
import moment from 'moment'
import './style.scss'

const validate = values => {
	const errors = {}
	const requiredFields = ['taskName', 'taskDate']
	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})
	return errors
}

const AddTask = ({ selectedUsersId, open, onClose, toastManager, match, taskRefetch }) => {
	const [submitting, setSubmitting] = useState(false)
	const [addTaskMutation] = useMutation(ADD_TASK)

	const {
		params: { sessionId },
	} = match

	// add task mutation
	const taskAddMutation = variables => {
		setSubmitting(true)
		addTaskMutation({
			variables,
		})
			.then(({ data }) => {
				if (data.createTask) {
					setSubmitting(false)
					onClose()
					taskRefetch()
					toastManager.add('Added successfully.', { appearance: 'success', autoDismiss: true })
				}
			})
			.catch(error => {
				console.log('value in error', error)
				setSubmitting(false)
			})
			.finally(() => {
				setSubmitting(false)
			})
	}

	const modalCloseHandler = useCallback(() => {
		onClose()
	}, [onClose])

	const {
		values: {
			taskName,
			description,
			taskDate,
			//  reference,
			upload,
			documentLink,
		},
		errors,
		handleSubmit,
		handleChange,
		handleImageChange,
		handleSelectChange,
	} = useForm(() => {
		if (upload != undefined) {
			if (upload.file.type) {
				var imgAllowed = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'image/gif', '.docx']
				var docAllowed = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
				var pptAllowed = ['application/vnd.openxmlformats-officedocument.presentationml.presentation']
				var videoAllowed = ['application/msword', 'video/mp4', 'audio/mpeg']
				var allowedTypes = imgAllowed.concat(docAllowed, pptAllowed, videoAllowed)
				if (!allowedTypes.includes(upload.file.type)) {
					var fileErr = 'Please upload a JPEG, PNG, GIF, PDF, MP3, MP4, PPT, DOC, DOCX file.'
					toastManager.add(fileErr, { appearance: 'error', autoDismiss: true })
					return false
				}
			}
			if (upload.file.size > 5 * 1000 * 1024) {
				toastManager.add('Maximum size of 5MB is allowed', { appearance: 'error', autoDismiss: true })
				return false
			}
		}
		const variables = {
			session_id: Number(sessionId),
			user_ids: selectedUsersId,
			name: taskName,
			// reference: reference != undefined ? reference : '',
			due_date: taskDate ? moment(moment(taskDate, 'DD/MM/YYYY')).format('YYYY-MM-DD') : null,
			description: description != undefined ? description : '',
			document: upload ? upload.file : undefined,
			document_link: documentLink ? documentLink : '',
		}
		taskAddMutation(variables)
	}, validate)

	if (submitting) return <Loader active />
	return (
		<div className="task-form">
			<Modal
				className="form-modal add-users-modal"
				open={open}
				size="small"
				closeOnDimmerClick={false}
				onClose={modalCloseHandler}
			>
				<Modal.Header>Add Task</Modal.Header>
				<Modal.Content>
					<form autoComplete="off" onSubmit={handleSubmit}>
						<Grid>
							<Grid.Row>
								<Grid.Column computer={16}>
									<Grid.Row className="users-list">
										<FormComponent
											customlabel="Task Name *"
											name="taskName"
											placeholder="Task Name"
											value={taskName || ''}
											onChange={handleChange}
											error={errors.taskName}
										/>
										<FormComponent.Textarea
											rows={6}
											label="Description"
											name="description"
											placeholder="Description"
											value={description || ''}
											onChange={handleChange}
											fluid
										/>

										<FormComponent.Date
											label="Date *"
											name="taskDate"
											value={taskDate || null}
											placeholder="Date"
											minDate={moment()}
											icon={false}
											clearIcon="close"
											dateFormat="DD/MM/YYYY"
											popupPosition="bottom left"
											clearable
											closable
											error={errors.taskDate}
											onChange={handleSelectChange}
										/>
										{/* <FormComponent
											customlabel="Reference"
											name="reference"
											placeholder="Reference"
											value={reference || ''}
											onChange={handleChange}
										/> */}
										<FormComponent
											customlabel={
												<>
													URL{' '}
													<Popup
														hoverable
														position="bottom center"
														trigger={<Icon name="info circle" size="large" />}
													>
														Use this to link any URL that you would like - eg. Google map,
														Website page.
													</Popup>
												</>
											}
											name="documentLink"
											placeholder="Url"
											value={documentLink || ''}
											onChange={handleChange}
										/>
										<div className="field-wrap">
											<span className="field-label">Upload Document</span>
											<label className="upload" htmlFor="Upload">
												<input
													name="upload"
													id="upload"
													type="file"
													accept=".xlsx,.xls,image/*,.doc, .docx,.ppt,
													.pptx,.pdf,video/mp4,.csv, text/csv"
													onChange={handleImageChange}
												/>
												<span className="upload-custom"></span>
												{upload && upload.file ? <b>File name: {upload.file.name}</b> : '-'}
											</label>
										</div>
									</Grid.Row>
									<Grid className="group-fields">
										<Grid.Column computer={8}>
											<Button
												className="reverse"
												size="medium"
												type="button"
												disabled={submitting}
												content="Cancel"
												fluid
												onClick={modalCloseHandler}
											/>
										</Grid.Column>
										<Grid.Column computer={8}>
											<Button
												type="button"
												size="medium"
												content="Add Task"
												loading={submitting}
												disabled={submitting}
												onClick={() => {
													handleSubmit()
												}}
												fluid
											/>
										</Grid.Column>
									</Grid>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</form>
				</Modal.Content>
			</Modal>
		</div>
	)
}

AddTask.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	selectedUsersId: PropTypes.array.isRequired,
	taskRefetch: PropTypes.func,
}

AddTask.defaultProps = {
	open: false,
	taskRefetch: () => {},
}

export default withToastManager(AddTask)
