import { useHistory } from 'react-router'
import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import classNames from 'classnames'

import { withContext } from 'Context'
import { GET_AVAILABLE_ACCOUNTS } from 'Queries/usersQueries'
import { CHANGE_ACCOUNT } from 'Mutations/usersMutations'
import { profileUpdateAction } from 'Actions/profileActions'
import { appLoadingSwitch as appLoadingSwitchAction } from 'Actions/appActions'
import { client } from '../../../store'

import DropdownItem from './DropdownItem'

import './style.scss'

const _CustomerLogo = ({ pointing, openMenu, user, updateProfile, appLoadingSwitch }) => {
	const [isAccountPhotoLoaded, setIsAccountPhotoLoaded] = useState(true)
	const [changeAccountLoading, setChangeAccount] = useState(false)
	const accountId = _.get(user, 'account.id')
	const isSigninToken = localStorage.getItem('isSigninToken')
	const history = useHistory()
	const { data, loading, error } = useQuery(GET_AVAILABLE_ACCOUNTS, {
		variables: { id: user.id || null },
		fetchPolicy: 'network-only',
	})
	const [changeAccount] = useMutation(CHANGE_ACCOUNT)

	if (loading)
		return (
			<div className="customer-logo">
				<Loader active inline />
			</div>
		)
	if (error) return null

	const handleAccountChange = (e, { value }) => {
		setChangeAccount(true)

		changeAccount({ variables: { account_id: value } })
			.then(({ data: { changeAccount } }) => {
				if (changeAccount) {
					client.resetStore()
					updateProfile({
						...user,
						account: changeAccount,
					})
					appLoadingSwitch()

					setTimeout(() => {
						appLoadingSwitch()
					})
					history.push('/dashboard')
				}
			})
			.finally(() => {
				setChangeAccount(false)
			})
	}

	const accounts = _.get(data, 'byId.accounts')

	const currentAccount = _.find(accounts, ['id', accountId]) || {}
	const accountsOptions = _.filter(accounts, ({ id }) => id !== accountId)

	return (
		<div className={classNames('customer-logo', { open_menu: openMenu })}>
			<Dropdown
				trigger={
					<div>
						{currentAccount.photo && isAccountPhotoLoaded ? (
							<img
								src={currentAccount.photo}
								alt={currentAccount.name}
								onError={() => setIsAccountPhotoLoaded(false)}
							/>
						) : (
							<span>{currentAccount.name}</span>
						)}
					</div>
				}
				value={accountId}
				onChange={handleAccountChange}
				icon={
					accountsOptions.length ? (
						<span className="icon-wrap">
							<i className="fas fa-angle-down" />
						</span>
					) : null
				}
				pointing={pointing}
			>
				<Dropdown.Menu>
					{isSigninToken && isSigninToken == 'true' ? null : (
						<>
							{_.map(accountsOptions, ({ id, name, photo }) => (
								<DropdownItem
									key={id}
									item={{
										value: id,
										text: name,
										image: { src: photo },
										onClick: handleAccountChange,
									}}
								/>
							))}
						</>
					)}
				</Dropdown.Menu>
			</Dropdown>

			{changeAccountLoading && (
				<Dimmer active>
					<Loader />
				</Dimmer>
			)}
		</div>
	)
}

_CustomerLogo.propTypes = {
	pointing: PropTypes.oneOf(['left', 'right']),
	openMenu: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	updateProfile: PropTypes.func.isRequired,
	appLoadingSwitch: PropTypes.func.isRequired,
}
_CustomerLogo.defaultProps = {
	pointing: null,
}

const CustomerLogo = withContext(
	([
		{
			app: { openMenu },
			user: { user },
		},
		dispatch,
	]) => ({
		// State
		openMenu,
		user,
		// Actions
		updateProfile: data => profileUpdateAction(data, dispatch),
		appLoadingSwitch: () => appLoadingSwitchAction(dispatch),
	}),
	_CustomerLogo,
)

export { CustomerLogo }
