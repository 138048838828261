import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Grid, Icon, Loader, Divider, Button } from 'semantic-ui-react'
import classNames from 'classnames'
import { isScorm } from 'Utils/scorm'
import { withContext } from 'Context'
import { AccessibilityReadableModal, PageMetaTags } from 'Common'
import { setSelectedSection } from 'Actions/subjectActions'
import { Routes } from 'Constants'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { allRoles, isAssessorRole, resolveRole, isManagerRole, isNoSignOffRole } from 'Utils/roles'
import Scorm from './Scorm'
import UserCard from '../UserCard'
import ManageOutcomes from '../ManageOutcomes'
import ProgressInfo from './ProgressInfo'
import AutoSignOffModalContent from './AutoSignOffModalContent'
import ManagerSignOffModalContent from './ManagerSignOffModalContent'
import { GET_SUBJECT_SECTION_BY_ID } from './queries'
//import { post } from '../../../../store/queries/rest/client'

import './style.scss'

const SubjectSectionInfo = ({
	match,
	_setSelectedSection,
	setBreadcrumbs,
	authUser,
	matchForExport,
	userRole,
	userRoleType,
}) => {
	const [user, setUser] = useState(null)
	//const [submitting, setSubmitting] = useState(false)
	const {
		params: { pivotId, sectionId, userId },
	} = matchForExport ? matchForExport : match

	const { data, error, loading } = useQuery(GET_SUBJECT_SECTION_BY_ID, {
		variables: { id: parseInt(sectionId), pivot_id: parseInt(pivotId), limit: 1, user_id: parseInt(userId) },
		fetchPolicy: 'network-only',
	})

	//const exceedOne = 'You have exceeded the number of retakes allowed. Please talk to '
	//const exceedTwo = 'your manager who can review your results and reset the assessment.'
	//const exceed = exceedOne + exceedTwo
	//const mExceedOne = 'Assessment locked: the learner has exceeded the number of retakes allowed for the assessment.'
	const mExceedTwo = ' Please review their results below. If you are happy for them to continue then you can unlock '
	//const mExceedThree = ' the assessment by using the Unlock Assessment button below.'
	//const mExceed = mExceedOne + mExceedTwo + mExceedThree

	useEffect(() => {
		console.log(mExceedTwo)
		if (!matchForExport)
			if (userId) {
				if (user && pivotId && name && subjectName)
					setBreadcrumbs([
						{
							name: 'Learners',
							path: Routes.USERS,
						},
						{
							name: `${user.first_name} ${user.last_name}`,
							path: Routes.USERS + '/' + user.id,
						},
						{
							name: subjectName,
							path: Routes.MANAGER + '/' + user.id + '/courses/' + pivotId,
						},
						{
							name: name,
						},
					])
			} else {
				if (name && pivotId && subjectName)
					setBreadcrumbs([
						{
							name: 'Courses',
							path: Routes.SUBJECTS.path,
						},
						{
							name: subjectName,
							path: Routes.SUBJECTS.path + '/' + pivotId,
						},
						{
							name: name,
						},
					])
			}
	}, [user, loading])

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const {
		name,
		description,
		img,
		retake,
		is_start,
		is_completed,
		progress,
		outcomes_remaining,
		completeOutcomes,
		countOutcomeQuestions,
		countOutcomeQuestionAnswers,
		outcomes,
		allOutcomes,
		type,
		assessment,
		firstAssessment,
		force_learning,
		outcomesLearningLinkCount,
		subject: { name: subjectName, auto_sign_off_enabled },
	} = data.byId

	if (isScorm(data.byId)) {
		const scormProps = {
			...data.byId,
			userSubjectId: pivotId,
			userSubjectSectionId: sectionId,
			isLearnerView: !userId,
		}
		return <Scorm {...scormProps} />
	}
	const learningProgressStats = outcomes => {
		return outcomes.reduce(
			(learningStats, outcome) => {
				if (outcome.learning_link) {
					learningStats.learning_link_count++
				}
				if (outcome.lastActive) {
					learningStats.learning_view_count++
				}
				return learningStats
			},
			{ learning_link_count: 0, learning_view_count: 0 },
		)
	}
	const learningStats = learningProgressStats(allOutcomes)
	const firstOutcome = outcomes.length > 0 ? outcomes[0].id : null
	const pro = progress
	let isForceLearning = false
	const isLearnerReadyForAssessment = () => {
		if (
			force_learning &&
			outcomesLearningLinkCount > 0 &&
			learningStats.learning_link_count !== learningStats.learning_view_count
		) {
			isForceLearning = true
		}
	}
	isLearnerReadyForAssessment()
	console.log(pro)
	const assessmentButton = retake
		? 'Retake Assessment'
		: is_completed
		? 'Assessment Completed'
		: is_start
		? 'Continue Assessment'
		: 'Start Assessment'

	const firstColWidth = userId
		? { width: 16 }
		: {
				mobile: 16,
				tablet: 9,
				computer: 9,
		} // eslint-disable-line

	const isManager = isManagerRole(authUser.account.role, authUser.account.roleType)
	const isAssessor = isAssessorRole(userRole, userRoleType)
	const isNoSignOffManager = isNoSignOffRole(authUser.account.role, authUser.account.roleType)
	/*const fnretake = () => {
		setSubmitting(true)
		const body = {
			subject_section_id: sectionId,
			user_id: userId,
			account_id: authUser.account,
			user_subjects_id: pivotId,
		}
		post('/update-intake', JSON.stringify(body)).then(response => {
			console.log(response)
			setSubmitting(false)
			window.location.reload()
		})
	}
	const learnerCheck = () => {
		if (is_completed && pro < 100) {
			return exceed
		} else {
			return ''
		}
	}
	const managerCheck = () => {
		if (is_completed && pro < 100) {
			return mExceed
		} else {
			return ''
		}
	}*/

	return (
		<>
			{!matchForExport && <PageMetaTags title={name} />}
			<div className="section-info">
				<Grid reversed="mobile">
					<Grid.Column {...firstColWidth}>
						<div className={classNames('page-title', { flex: userId })}>
							{userId && <UserCard setUser={setUser} />}

							<h1>{name}</h1>

							{!userId && <p>{description}</p>}
							{!userId && (
								<div className="mt_25">
									<p className="page-title-text">
										The{' '}
										<AccessibilityReadableModal
											className="instructions-modal"
											size="tiny"
											trigger={<i className="fas fa-info-circle" style={{ cursor: 'pointer' }} />}
											closeIcon
										>
											{auto_sign_off_enabled ? (
												<AutoSignOffModalContent />
											) : (
												<ManagerSignOffModalContent />
											)}
										</AccessibilityReadableModal>{' '}
										has instructions on how to complete this course.
									</p>
								</div>
							)}
							{!userId && (
								<ProgressInfo
									auto_sign_off_enabled={auto_sign_off_enabled}
									outcomes_remaining={outcomes_remaining}
									progress={progress}
									completeOutcomes={completeOutcomes}
									countOutcomeQuestions={countOutcomeQuestions}
									learning_stats={learningStats}
									countOutcomeQuestionAnswers={countOutcomeQuestionAnswers}
									assessment={assessment}
									firstAssessment={firstAssessment}
									isManager={Boolean(userId)}
								/>
							)}
						</div>
						{userId && (
							<>
								<div className="mt_25">
									<p className="page-title-text">
										The{' '}
										<AccessibilityReadableModal
											className="instructions-modal"
											size="tiny"
											trigger={<i className="fas fa-info-circle" style={{ cursor: 'pointer' }} />}
											closeIcon
										>
											{auto_sign_off_enabled ? (
												<AutoSignOffModalContent />
											) : (
												<ManagerSignOffModalContent />
											)}
										</AccessibilityReadableModal>{' '}
										has instructions on how to complete this course.
									</p>
								</div>
								<div className="mb_18">
									<Divider />
								</div>
							</>
						)}
						{userId && (
							<ProgressInfo
								auto_sign_off_enabled={auto_sign_off_enabled}
								outcomes_remaining={outcomes_remaining}
								progress={progress}
								completeOutcomes={completeOutcomes}
								countOutcomeQuestions={countOutcomeQuestions}
								learning_stats={learningStats}
								countOutcomeQuestionAnswers={countOutcomeQuestionAnswers}
								assessment={assessment}
								firstAssessment={firstAssessment}
								isManager={Boolean(userId)}
							/>
						)}
						{/*
					{!isManager && authUser.account.id != 122 && <p className="section-retake">{learnerCheck()}</p>}
					{isManager && authUser.account.id != 122 && is_completed && (
						<p className="section-retake">{managerCheck()}</p>
					)}
					{authUser.account.role == 6 && authUser.account.id != 122 && is_completed && pro < 100 && (
						<Button
							onClick={fnretake}
							content="Unlock assessment"
							loading={submitting}
							disabled={submitting}
						/>
					)}
					{authUser.account.role == 1 && authUser.account.id != 122 && is_completed && pro < 100 && (
						<Button
							onClick={fnretake}
							content="Unlock assessment"
							loading={submitting}
							disabled={submitting}
						/>
					)}
					*/}
					</Grid.Column>

					{!userId && (
						<Grid.Column className="text-right" only="tablet computer" tablet={7} computer={7}>
							<img src={img} alt="" />
						</Grid.Column>
					)}
				</Grid>

				{!userId && (
					<div className="actions">
						{type !== 'No learning' && (
							<Link
								className="button-link"
								to={`${Routes.SUBJECTS.path}/${pivotId}/${sectionId}/learn${
									type === 'Text blocks' ? `/${firstOutcome}` : ''
								}`}
							>
								Start Learning from beginning
							</Link>
						)}
						{is_completed || isForceLearning ? (
							<Button className="btn-not-allowed btn-assessment">
								{assessmentButton}
								{is_completed && <Icon name="check" />}
							</Button>
						) : (
							<Link
								className="button-link secondary"
								to={Routes.ASSESSMENT.path}
								disabled={is_completed}
								onClick={e => {
									is_completed ? e.preventDefault() : _setSelectedSection({ ...data.byId, pivotId })
								}}
							>
								{assessmentButton}
								{is_completed && <Icon name="check" />}
							</Link>
						)}
						<AccessibilityReadableModal
							className="instructions-modal"
							size="tiny"
							trigger={<Icon name="info circle" size="large" />}
							closeIcon
						>
							{auto_sign_off_enabled ? <AutoSignOffModalContent /> : <ManagerSignOffModalContent />}
						</AccessibilityReadableModal>
					</div>
				)}
			</div>

			<ManageOutcomes
				matchForExport={matchForExport}
				noSignOff={isNoSignOffManager}
				manager={(isManager || isAssessor) && parseInt(userId) !== authUser.id}
				learning_stats={learningStats}
			/>
		</>
	)
}

SubjectSectionInfo.propTypes = {
	authUser: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
	matchForExport: PropTypes.object,
	_setSelectedSection: PropTypes.func,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

SubjectSectionInfo.defaultProps = {
	_setSelectedSection: () => {},
	matchForExport: null,
}

export default withBreadcrumbs(
	withRouter(
		withContext(
			([
				{
					user: { user },
				},
				dispatch,
			]) => ({
				authUser: user,
				...resolveRole(user),
				// actions
				_setSelectedSection: data => setSelectedSection(data, dispatch),
			}),
			SubjectSectionInfo,
		),
	),
)
