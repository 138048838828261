import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import { Loader } from 'semantic-ui-react'
import classNames from 'classnames'
import _ from 'lodash'

import { GET_SECTION_OUTCOME_BY_ID } from 'Queries/subjectsQueries'
import { Routes } from 'Constants'

import Bookmark from './Bookmark'

import './style.scss'

const OutcomeContent = ({ match, activeOutcomeIndex, outcomes }) => {
	const {
		params: { pivotId, sectionId, outcomeId },
	} = match
	const { data, error, loading } = useQuery(GET_SECTION_OUTCOME_BY_ID, {
		variables: { id: outcomeId, subjectSectionId: sectionId, pivot_id: pivotId },
		fetchPolicy: 'network-only',
	})

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const { name, learningContent } = data.byId
	const prevOutcome = outcomes[activeOutcomeIndex - 1] ? outcomes[activeOutcomeIndex - 1].id : 0
	const nextOutcome =
		activeOutcomeIndex !== outcomes.length - 1
			? outcomes[activeOutcomeIndex + 1].id
			: outcomes[outcomes.length - 1].id

	return (
		<div className="outcome-content">
			<h2>{name}</h2>
			{_.map(learningContent, ({ id, name, description, bookmark }) => {
				return (
					<div key={id} className="content-item">
						<h4>{name}</h4>
						<p>{description}</p>
						<Bookmark bookmark={bookmark} contentId={id} />
					</div>
				)
			})}
			<div className="actions">
				<Link
					to={`${Routes.SUBJECTS.path}/${pivotId}/${sectionId}/learn/${prevOutcome}`}
					className={classNames('button-link', { disable: activeOutcomeIndex === 0 })}
				>
					Previous
				</Link>
				<Link
					to={`${Routes.SUBJECTS.path}/${pivotId}/${sectionId}/learn/${nextOutcome}`}
					className={classNames('button-link', { disable: activeOutcomeIndex === outcomes.length - 1 })}
				>
					Next
				</Link>
			</div>
		</div>
	)
}

OutcomeContent.propTypes = {
	match: PropTypes.object.isRequired,
	activeOutcomeIndex: PropTypes.number,
	outcomes: PropTypes.array,
}

OutcomeContent.defaultProps = {
	activeOutcomeIndex: 0,
	outcomes: [],
}

export default withRouter(OutcomeContent)
