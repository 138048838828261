import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'
import { Modal, Button } from 'semantic-ui-react'
import _ from 'lodash'

import { DELETE_GROUP, UPDATE_CREATE_GROUP } from 'Mutations/groupsMutations'
import { useForm } from 'Hooks'
import { withContext } from 'Context/index'

import FormComponent from 'Helpers/form'
import { GroupSelect } from 'Common/GroupSelect'

const validate = values => {
	const errors = {}
	const requiredFields = ['name']

	requiredFields.forEach(field => {
		if (!values[field]) errors[field] = true
	})

	return errors
}

const CreateUpdateGroup = ({
	open,
	initialValues,
	onClose,
	activeIds,
	loadParentGroup,
	query,
	accountName,
	accountRole,
	accountRoleType,
}) => {
	const [sending, setSending] = useState(false)

	const [deleteGroup] = useMutation(DELETE_GROUP)
	const [updateCreateGroup] = useMutation(UPDATE_CREATE_GROUP)

	const {
		values: { name, parent_id },
		errors,
		handleSubmit,
		handleChange,
		handleSelectChange,
	} = useForm(
		() => {
			setSending(true)

			const variables = {
				rebuild_tree: {
					name,
					parent_id,
				},
			}

			if (initialValues && initialValues.id) {
				variables.rebuild_tree.id = initialValues.id
			}

			updateCreateGroup({ variables })
				.then(() => {
					loadParentGroup({
						variables: {
							with_child: activeIds,
							search: query,
						},
					})
				})
				.finally(() => {
					setSending(false)
					onClose()
				})
		},
		validate,
		initialValues,
	)

	const groupsOptions = useMemo(() => {
		const groupsOptions = []

		if (accountRole === 1 && accountRoleType === 'account') {
			groupsOptions.unshift({
				key: 0,
				value: 0,
				text: accountName,
			})
		}

		return groupsOptions
	}, [accountRole, accountRoleType, accountName])

	// eslint-disable-next-line no-case-declarations
	const handlingErrors = !_.isEmpty(initialValues) && initialValues.id === parent_id

	const modalContent = (
		<form onSubmit={handleSubmit}>
			<FormComponent
				customlabel="name"
				name="name"
				value={name || ''}
				onChange={handleChange}
				placeholder="Enter name"
				error={errors.name}
			/>
			<GroupSelect
				label="Parent"
				name="parent_id"
				value={parent_id || 0}
				onChange={handleSelectChange}
				placeholder="Chose group parent"
				additionalOptions={groupsOptions}
				selectOnBlur={false}
				error={errors.parent_id || handlingErrors}
				fluid
			/>
			<div className="actions">
				{initialValues.id && (
					<Button
						type="button"
						disabled={!initialValues.deletable || sending}
						negative
						content="Delete group"
						onClick={() =>
							deleteGroup({
								variables: {
									id: initialValues.id,
								},
							})
								.then(() =>
									loadParentGroup({
										variables: {
											with_child: activeIds,
											search: query,
										},
									}),
								)
								.finally(() => onClose())
						}
					/>
				)}
				<Button type="button" content="Cancel" disabled={sending} onClick={onClose} />
				<Button content="Save" loading={sending} disabled={handlingErrors || sending} />
			</div>
		</form>
	)

	return (
		<Modal className="create-update-group-modal" open={open} size="mini" onClose={onClose}>
			<Modal.Header>{`${!_.isEmpty(initialValues) ? 'Edit' : 'Add'} Group`}</Modal.Header>
			<Modal.Content>{modalContent}</Modal.Content>
		</Modal>
	)
}

CreateUpdateGroup.propTypes = {
	open: PropTypes.bool,
	initialValues: PropTypes.object,
	onClose: PropTypes.func,
	loadParentGroup: PropTypes.func.isRequired,
	accountName: PropTypes.string.isRequired,
	accountRole: PropTypes.number.isRequired,
	accountRoleType: PropTypes.string.isRequired,
	activeIds: PropTypes.arrayOf(PropTypes.number),
	query: PropTypes.string,
}

CreateUpdateGroup.defaultProps = {
	open: false,
	initialValues: {},
	activeIds: null,
	query: null,
	onClose: () => {},
}

export default withContext(
	([
		{
			user: {
				user: {
					account: { name, role, roleType },
				},
			},
		},
	]) => ({
		accountName: name,
		accountRole: role,
		accountRoleType: roleType,
	}),
	CreateUpdateGroup,
)
