import React, { useState, useCallback } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button, Icon, Confirm, Grid } from 'semantic-ui-react'
import { useMutation } from '@apollo/react-hooks'
import { REMOVE_EVIDENCE } from 'Mutations/settingMutations'
import { withRouter } from 'react-router-dom'
import { withToastManager } from 'react-toast-notifications'
import { withContext } from '../../../../context'

const EvidenceItem = ({
	id,
	isManager,
	created_at,
	user,
	evidence,
	sign_off,
	type: { name: typeName },
	authUser,
	history,
	match,
	documents,
	evidenceRefetch,
	toastManager,
	openModalHandler,
	openCrossReferenceModalHandler,
	delegateSessionById,
	showEviModalOpen,
}) => {
	const [viewMore, setViewMore] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [currentEviId, setCurrentEviId] = useState(0)

	const formattedDate = moment(created_at).format('DD/MM/YYYY')
	const [removeEvidence] = useMutation(REMOVE_EVIDENCE)

	const evidenceView = () => {
		switch (typeName) {
			case 'Results PDF':
				return <Button size="large" content="View PDF" onClick={() => window.open(evidence)} />
			case 'Learning Archive':
				return (
					<Button
						size="large"
						content="View Learning Archive"
						onClick={() => history.push(match.url + '/learning-archive')}
					/>
				)
			default:
				return (
					<p>
						{/* eslint-disable-next-line max-len */}
						{evidence.length > 130 && !viewMore ? `${evidence.slice(0, 130)}...` : evidence}{' '}
						{evidence.length > 130 && (
							<button
								type="button"
								className="view-more blue-text"
								onClick={viewMore ? () => setViewMore(false) : () => setViewMore(true)}
							>{`view ${viewMore ? 'less' : 'more'}`}</button>
						)}
					</p>
				)
		}
	}

	const onConfirm = useCallback(() => {
		removeEvidence({
			variables: { evidence_document_id: currentEviId },
		})
			.then(({ data }) => {
				if (data.deleteEvidenceDocument) {
					toastManager.add('Deleted Successfully.', { appearance: 'success', autoDismiss: true })
					evidenceRefetch()
				}
			})
			.catch(error => {
				console.log('error', error)
			})
			.finally(error => {
				console.log('error', error)
			})
		setOpenDeleteModal(false)
	}, [openDeleteModal])

	const onCancel = useCallback(() => {
		setOpenDeleteModal(false)
	}, [openDeleteModal])

	return (
		<div id={`evidence-${id}`} className="evidence-item">
			<div className="top-items">
				<Grid columns={4}>
					<Grid.Row>
						<Grid.Column>
							<span className="blue-text">{typeName === 'It depends' ? 'Note' : typeName}</span>
						</Grid.Column>
						<Grid.Column>
							<span className="dark-text">
								{user &&
									`Add ${sign_off ? '& sign off' : ''} by ${user.first_name} ${
										user.last_name
										// eslint-disable-next-line no-mixed-spaces-and-tabs
									} on ${formattedDate}`}
							</span>
						</Grid.Column>
						<Grid.Column />
						<Grid.Column>
							{delegateSessionById && isManager ? (
								<Button size="small" onClick={() => openCrossReferenceModalHandler(id)} fluid>
									<Icon name="linkify" color="grey" size="big" />
									Cross Reference
								</Button>
							) : null}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
			{evidenceView()}
			{delegateSessionById && !delegateSessionById.is_completed
				? user /* eslint-disable no-mixed-spaces-and-tabs */ &&
				  authUser.id === user.id && (
						<Icon name="edit outline" className="cursor-pointer" onClick={() => openModalHandler(id)} />
				  ) /* eslint-enable no-mixed-spaces-and-tabs */
				: null}
			<p>
				{documents.length > 0
					? documents.map(list => {
							return (
								<>
									<div
										role="button"
										tabIndex="0"
										onKeyDown={() => showEviModalOpen(list.document)}
										onClick={() => showEviModalOpen(list.document)}
										className="clr-black"
									>
										{list.document_name}
									</div>

									{delegateSessionById && !delegateSessionById.is_completed
										? /* eslint-disable no-mixed-spaces-and-tabs */
										  user &&
										  authUser.id === user.id && (
												<Icon
													key={list.id}
													className="cursor-pointer"
													name="trash"
													onClick={() => {
														setOpenDeleteModal(true)
														setCurrentEviId(list.id)
													}}
												/>
										  ) /* eslint-enable no-mixed-spaces-and-tabs */
										: null}
								</>
							)
					  }) // eslint-disable-line no-mixed-spaces-and-tabs
					: null}
			</p>

			<Confirm
				open={openDeleteModal}
				size="mini"
				content="You are about to delete the Document?"
				cancelButton={<Button className="reverse" disabled={false} content="Cancel" />}
				confirmButton={<Button loading={false} disabled={false} content="Confirm" />}
				onCancel={onCancel}
				onConfirm={onConfirm}
			/>
		</div>
	)
}

EvidenceItem.propTypes = {
	id: PropTypes.number.isRequired,
	created_at: PropTypes.string.isRequired,
	user: PropTypes.object,
	evidence: PropTypes.string,
	type: PropTypes.object.isRequired,
	authUser: PropTypes.object.isRequired,
	sign_off: PropTypes.bool,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	documents: PropTypes.array.isRequired,
	evidenceRefetch: PropTypes.func.isRequired,
	toastManager: PropTypes.object.isRequired,
	openModalHandler: PropTypes.func.isRequired,
	openCrossReferenceModalHandler: PropTypes.func.isRequired,
	delegateSessionById: PropTypes.object,
	showEviModalOpen: PropTypes.func.isRequired,
	isManager: PropTypes.bool.isRequired,
}

EvidenceItem.defaultProps = {
	user: null,
	evidence: '',
	sign_off: null,
	delegateSessionById: null,
}

export default withRouter(
	withContext(
		([
			{
				user: { user },
			},
		]) => ({ authUser: user }),
		withToastManager(EvidenceItem),
	),
)
