import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Loader } from 'semantic-ui-react'

import EditProfile from 'Modals/users/EditProfile'
import ChangeStatus from 'Modals/users/ChangeStatus'
import AllocateAssessor from 'Modals/users/AllocateAssessor'

import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import { PageMetaTags } from 'Common'
import { allRoles, isReviewerRole, isAssessorRole, resolveRole } from 'Utils/roles'
import { withContext } from 'Context'

import { Routes } from 'Constants'
import { GET_USER_BY_ID } from './queries'

import UserCard from './UserCard'
import UserSubjects from './UserSubjects'
import UserLearningArchive from './UserLearningArchive'

import './style.scss'

const COURSE_VIEW_ACTUAL = 'actual'
const COURSE_VIEW_ARCHIVE = 'archive'

const coursesViews = {
	[COURSE_VIEW_ACTUAL]: {
		component: UserSubjects,
		switchTo: COURSE_VIEW_ARCHIVE,
	},
	[COURSE_VIEW_ARCHIVE]: {
		component: UserLearningArchive,
		switchTo: COURSE_VIEW_ACTUAL,
	},
}

const SingleUser = ({ match, setBreadcrumbs, userRole, userRoleType }) => {
	const [profileModal, setProfileModal] = useState(false)
	const [statusModal, setStatusModal] = useState(false)
	const [assessorModal, setAssessorModal] = useState(false)
	const [coursesView, setCoursesView] = useState(COURSE_VIEW_ACTUAL)

	const {
		params: { id },
	} = match

	const { loading, error, data } = useQuery(GET_USER_BY_ID, {
		variables: { id },
		fetchPolicy: 'network-only',
	})

	const currentCoursesViews = coursesViews[coursesView]
	const CoursesViewComponent = currentCoursesViews.component

	const handleStatusModal = useCallback(() => {
		setStatusModal(!statusModal)
	}, [statusModal])

	const handleProfileModal = useCallback(() => {
		setProfileModal(!profileModal)
	}, [profileModal])

	const handleAssessorModal = useCallback(() => {
		setAssessorModal(value => !value)
	}, [])

	const handleSwitchCoursesView = useCallback(() => {
		setCoursesView(currentCoursesViews.switchTo)
	}, [currentCoursesViews])

	useEffect(() => {
		if (first_name && last_name)
			setBreadcrumbs([
				match.path === '/activity/:id'
					? {
							name: 'Activity',
							path: Routes.ACTIVITY,
					  } // eslint-disable-line
					: {
							name: 'Learners',
							path: Routes.USERS,
					  }, // eslint-disable-line
				{
					name: `${first_name} ${last_name}`,
				},
			])
	}, [loading])

	if (loading || !data || !data.byId) return <Loader active />
	if (error) return <span>Something went wrong :(</span>

	const {
		first_name,
		last_name,
		email,
		secondary_email,
		username,
		currentAccount: { is_active, role, uid },
		groups,
	} = data.byId

	const groupId = groups[0] ? groups[0].id : 0
	const groupRole = groups[0] ? groups[0].role : role

	const isReviewer = isReviewerRole(userRole, userRoleType)
	const isAssessor = isAssessorRole(userRole, userRoleType)

	return (
		<div className="single-user">
			<PageMetaTags title={`${first_name} ${last_name}`} />
			<UserCard
				isReviewer={isReviewer}
				isAssessor={isAssessor}
				user={data.byId}
				openProfileModal={handleProfileModal}
				openStatusModal={handleStatusModal}
				openAssessorModal={handleAssessorModal}
			/>

			<CoursesViewComponent switchCoursesView={handleSwitchCoursesView} />

			{!isReviewer && !isAssessor && profileModal && (
				<EditProfile
					open={profileModal}
					initialValues={{
						first_name,
						last_name,
						email,
						secondary_email,
						username,
						group: groupId,
						group_role: groupRole,
						uid,
					}}
					onClose={handleProfileModal}
				/>
			)}

			{!isReviewer && !isAssessor && statusModal && (
				<ChangeStatus
					open={statusModal}
					is_active={is_active}
					initialValues={{ first_name, last_name, email, username }}
					onClose={handleStatusModal}
				/>
			)}

			{!isReviewer && !isAssessor && assessorModal && (
				<AllocateAssessor open={assessorModal} onClose={handleAssessorModal} />
			)}
		</div>
	)
}

SingleUser.propTypes = {
	match: PropTypes.object.isRequired,
	setBreadcrumbs: PropTypes.func.isRequired,
	userRole: PropTypes.number.isRequired,
	userRoleType: PropTypes.oneOf(Object.keys(allRoles)).isRequired,
}

export default withContext(
	([
		{
			user: { user },
		},
	]) => ({
		...resolveRole(user),
	}),
	withRouter(withBreadcrumbs(SingleUser)),
)
