import React, { useEffect, useState, useCallback } from 'react'
import withBreadcrumbs from 'Common/Breadcrumbs/withBreadcrumbs'
import PropTypes from 'prop-types'
import { useHistory, generatePath } from 'react-router-dom'
import { Routes } from 'Constants'
import { Grid, Button, Loader, Tab } from 'semantic-ui-react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { withContext } from 'Context'
import _ from 'lodash'
import { withToastManager } from 'react-toast-notifications'
import { GET_TAG_BYID, GET_ALL_SUBJECT, ACCOUNT_SESSION_LIST } from 'Queries/settingQueries'
import { REMOVE_TAG_COURSES, REMOVE_TAG_SESSIONS } from 'Mutations/settingMutations'
import AddTags from 'Modals/tags/AddTags'
import TaskListing from './TagListing'
import SessionTable from './SessionList'
import './style.scss'

const TagList = ({ match, setBreadcrumbs, toastManager, customFilter }) => {
	const history = useHistory()
	const {
		params: { id },
	} = match
	const [filter, onFilter] = useState({
		page: 1,
		perPage: 15,
		name: '',
		tag_id: id,
	})
	const [sessionVisible, setSessionVisible] = useState(false)
	const [openModal, setOpenModal] = useState(false)
	const [selectedUsers, setSelectedUsers] = useState([])
	const [tagCourse, setTagCourse] = useState([])
	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmSessionModal, setConfirmSessionModal] = useState(false)
	const [removeTagCourses] = useMutation(REMOVE_TAG_COURSES)
	const [removeTagSessions] = useMutation(REMOVE_TAG_SESSIONS)
	const [currentSubjectId, setCurrentSubjectId] = useState(0)
	const [currentSessionId, setCurrentSessionId] = useState(0)

	// query to get edit modal data
	const { data, refetch } = useQuery(GET_TAG_BYID, {
		variables: {
			id: id,
		},
		fetchPolicy: 'network-only',
	})
	// query for list data
	const { data: courseData, error, loading, refetch: courseDataRefetch } = useQuery(GET_ALL_SUBJECT, {
		variables: {
			filters: filter,
		},
		fetchPolicy: 'network-only',
	})
	// query for session data
	const { data: sessionData, loading: sessionLoading, refetch: sessionRefetch } = useQuery(ACCOUNT_SESSION_LIST, {
		variables: {
			filters: {
				title: '',
				tag_id: id,
				sort_param: customFilter.sort_param,
				sort_direction: customFilter.sort_direction,
			},
		},
		fetchPolicy: 'network-only',
	})

	useEffect(() => {
		if (courseData) setTagCourse(courseData.AccountSubjects.data.data)
	}, [courseData])
	if (data) {
		var { name, description } = data.byId
	}

	useEffect(() => {
		if (sessionData) {
			if (sessionData.accountSessions.data.data.length > 0) setSessionVisible(true)
		}
	}, [sessionData])

	useEffect(() => {
		setBreadcrumbs([
			{
				name: 'Settings',
				path: '/settings',
			},
			{
				name: 'Tags',
				path: '/settings/tags',
			},
			{
				name: name ? name : '-',
			},
		])
		// getEditData({ variables: { id: id } })
	}, [data])

	const handleLearners = () => {
		history.push({
			pathname: generatePath(Routes.TAGS_Add_Courses, {
				id: id,
			}),
		})
	}

	const EditUserModalCloseHandler = () => {
		setOpenModal(false)
		refetch()
	}

	// set pages in pagination
	const handleSetPage = useCallback(
		page => {
			onFilter(prevState => ({
				...prevState,
				page: page,
			}))
		},
		[onFilter],
	)
	// set sorting
	const handleSortTable = useCallback(
		e => {
			const paramName = _.get(e, 'currentTarget.dataset.sortParam', e)

			const newActiveParam = { name: filter.sort_param, order: filter.sort_direction }

			if (_.isEqual(newActiveParam.name, paramName)) {
				switch (newActiveParam.order) {
					case 'asc':
						newActiveParam.order = 'desc'
						break
					case 'desc':
						newActiveParam.name = ''
						newActiveParam.order = 'asc'
						break
				}
			} else {
				newActiveParam.name = paramName
				newActiveParam.order = 'asc'
			}

			onFilter(prevState => ({
				...prevState,
				sort_param: newActiveParam.name,
				sort_direction: newActiveParam.order,
				page: 1,
			}))
		},
		[onFilter, filter],
	)

	const viewLearner = useCallback(
		id => {
			setCurrentSubjectId(id)
			setConfirmModal(true)
		},
		[confirmModal, currentSubjectId],
	)

	const viewDeleteSession = useCallback(
		id => {
			setCurrentSessionId(id)
			setConfirmSessionModal(true)
		},
		[confirmSessionModal, currentSessionId],
	)

	const onConfirm = useCallback(() => {
		removeTagCourses({
			variables: { subject_id: currentSubjectId, tag_id: id },
		})
			.then(({ data }) => {
				if (data.removeSubject) {
					toastManager.add('Delete Successfully', { appearance: 'success', autoDismiss: true })
					courseDataRefetch()
					setCurrentSubjectId(0)
				}
			})
			.catch(error => {
				console.log('error', error)
				setCurrentSubjectId(0)
			})
			.finally(() => {
				setCurrentSubjectId(0)
			})
		setConfirmModal(false)
	}, [confirmModal])

	const onCancel = useCallback(() => {
		setConfirmModal(false)
	}, [confirmModal])

	const onSessionDeleteConfirm = useCallback(() => {
		removeTagSessions({
			variables: { session_id: currentSessionId, tag_id: id },
		})
			.then(({ data }) => {
				if (data.removeSession) {
					toastManager.add('Delete Successfully', { appearance: 'success', autoDismiss: true })
					sessionRefetch()
					setCurrentSessionId(0)
				}
			})
			.catch(error => {
				console.log('error', error)
				setCurrentSessionId(0)
			})
			.finally(() => {
				setCurrentSessionId(0)
			})
		setConfirmSessionModal(false)
	}, [confirmSessionModal])

	const onSessionDeleteCancel = useCallback(() => {
		setConfirmSessionModal(false)
	}, [confirmSessionModal])

	const panes = [
		{
			menuItem: 'Courses',
			render: () => (
				<Tab.Pane>
					<TaskListing
						usersList={tagCourse}
						page={courseData.AccountSubjects.data.current_page}
						setPage={handleSetPage}
						perPage={courseData.AccountSubjects.data.per_page}
						totalCount={courseData.AccountSubjects.data.total}
						length={courseData.AccountSubjects.data.total}
						handleSortTable={handleSortTable}
						selectedUsers={selectedUsers}
						setSelectedUsers={setSelectedUsers}
						activeSortParam={{
							name: filter.sort_param || '',
							order: filter.sort_direction || 'asc',
						}}
						confirmModal={confirmModal}
						viewLearner={viewLearner}
						onConfirm={onConfirm}
						onCancel={onCancel}
					/>
				</Tab.Pane>
			),
		},
		sessionVisible
			? {
					menuItem: 'Sessions',
					render: () => (
						<Tab.Pane>
							<SessionTable
								sessionData={sessionData}
								sessionLoading={sessionLoading}
								confirmModal={confirmSessionModal}
								viewLearner={viewDeleteSession}
								onConfirm={onSessionDeleteConfirm}
								onCancel={onSessionDeleteCancel}
							/>
						</Tab.Pane>
					),
			  } // eslint-disable-line no-mixed-spaces-and-tabs
			: null,
	]

	if (loading) return <Loader active />
	if (error) return <span>Something went wrong :(</span>
	return (
		<div className="tags-listing">
			<div className="page-title" style={{ marginBottom: '50px' }}>
				<Grid columns={4}>
					<Grid.Row>
						<Grid.Column className="tag-title">
							<h2>{name ? name : '-'}</h2>
							<h3>{description ? description : '-'}</h3>
						</Grid.Column>
						<Grid.Column />
						<Grid.Column>
							<Button size="big" onClick={handleLearners} fluid content="Add Learning" />
						</Grid.Column>
						<Grid.Column>
							<Button
								size="big"
								onClick={() => {
									setOpenModal(true)
								}}
								fluid
								content="Edit Tag"
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
			<div className="tabs-view">
				<Tab panes={panes} />
			</div>

			{openModal && name && (
				<AddTags
					open={openModal}
					onClose={EditUserModalCloseHandler}
					initialFormData={{
						id,
						tag_name: name,
						description: description,
					}}
				/>
			)}
		</div>
	)
}

TagList.propTypes = {
	setBreadcrumbs: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	toastManager: PropTypes.object.isRequired,
	customFilter: PropTypes.object.isRequired,
}

export default withContext(
	([
		{
			users: { customFilter },
		},
	]) => ({
		// state
		customFilter,
	}),
	withBreadcrumbs(withToastManager(TagList)),
)
