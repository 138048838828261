import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import propTypes from 'prop-types'

import { SET_BOOKMARK } from 'Mutations/outcomeMutations'

const Bookmark = ({ bookmark, contentId }) => {
	const [bookmarkId, setBookmarkId] = useState(bookmark ? bookmark.id : null)
	const [toggleBookmark] = useMutation(SET_BOOKMARK)
	return (
		<button
			className="bookmark"
			type="button"
			onClick={() =>
				toggleBookmark({
					variables: {
						learning_content_id: contentId,
						bookmark_id: bookmarkId,
					},
				}).then(({ data: { refresh } }) => {
					if (refresh) setBookmarkId(refresh.id)
					else setBookmarkId(null)
				})
			}
		>
			<i className={`fa${bookmarkId ? 's' : 'r'} fa-bookmark`} />
		</button>
	)
}

Bookmark.propTypes = {
	contentId: propTypes.number.isRequired,
	bookmark: propTypes.object,
}

Bookmark.defaultProps = {
	bookmark: null,
}

export default Bookmark
